import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const ScrollView = ({ children }) => {
    const [scrollView, setScrollView] = useState(null);
    const [scrollViewHeight, setScrollViewHeight] = useState(0);

    useEffect(() => {
        if (scrollView) {
            const resizeHandler = () => {
                setScrollViewHeight(
                    window.innerHeight -
                        scrollView.getBoundingClientRect().top -
                        25,
                );
            };

            resizeHandler();
            window.addEventListener('resize', resizeHandler);

            return () => {
                window.removeEventListener('resize', resizeHandler);
            };
        }

        return () => {};
    }, [scrollView]);

    return (
        <div
            style={{ height: scrollViewHeight, overflow: 'auto' }}
            ref={(el) => setScrollView(el)}
        >
            {children}
        </div>
    );
};

ScrollView.propTypes = {
    children: PropTypes.element.isRequired,
};

export default ScrollView;
