import {
    FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_SUCCESS_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_FAILURE_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_SUCCESS_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_FAILURE_ACTION,
    FETCH_BEST_DEPARTMENT_ACTION,
    FETCH_BEST_DEPARTMENT_SUCCESS_ACTION,
    FETCH_BEST_DEPARTMENT_FAILURE_ACTION,
} from './employeePerformance.action-types';

const initialState = {
    isLoadingEmployeeDepartmentModulesPerformance: false,
    error: null,
    employeeDepartmentModulesPerformance: [],
    isLoadingEmployeeDepartmentPlayoffPerformance: false,
    employeeDepartmentPlayoffPerformance: [],
    isLoadingBestDepartment: false,
    bestDepartmentName: {},
};

export function employeePerformance(state = initialState, action) {
    switch (action.type) {
        case FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_ACTION: {
            return {
                ...state,
                employeeDepartmentModulesPerformance: true,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_SUCCESS_ACTION: {
            return {
                ...state,
                employeeDepartmentModulesPerformance:
                    action.employeeDepartmentModulesPerformance,
                isLoadingEmployeeDepartmentModulesPerformance: false,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_FAILURE_ACTION: {
            return {
                ...state,
                employeeDepartmentModulesPerformance: {},
                isLoadingEmployeeDepartmentModulesPerformance: false,
                error: action.error,
            };
        }
        case FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_ACTION: {
            return {
                ...state,
                isLoadingEmployeeDepartmentPlayoffPerformance: true,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_SUCCESS_ACTION: {
            return {
                ...state,
                employeeDepartmentPlayoffPerformance:
                    action.employeeDepartmentPlayoffPerformance,
                isLoadingEmployeeDepartmentPlayoffPerformance: false,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_FAILURE_ACTION: {
            return {
                ...state,
                employeeDepartmentPlayoffPerformance: {},
                isLoadingEmployeeDepartmentPlayoffPerformance: false,
                error: action.error,
            };
        }
        case FETCH_BEST_DEPARTMENT_ACTION: {
            return {
                ...state,
                isLoadingBestDepartment: true,
                error: null,
            };
        }
        case FETCH_BEST_DEPARTMENT_SUCCESS_ACTION: {
            return {
                ...state,
                bestDepartmentName: action.bestDepartmentName,
                isLoadingBestDepartment: false,
                error: null,
            };
        }
        case FETCH_BEST_DEPARTMENT_FAILURE_ACTION: {
            return {
                ...state,
                bestDepartmentName: {},
                isLoadingBestDepartment: false,
                error: action.error,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
