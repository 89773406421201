import { withStyles } from '@material-ui/core/styles';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { styles } from './styles';

function beforeDraw(chart) {
    const { width, height, ctx } = chart.chart;
    ctx.restore();
    ctx.font = `600 25px "Poppins", sans-serif`;
    ctx.textBaseline = 'middle';
    const { text } = chart.config.options.elements.center;
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2 + 2;
    ctx.fillStyle = '#0A96BE';
    ctx.fillText(text, textX, textY);
    ctx.save();
}

const defaultOptions = {
    tooltips: { enabled: false },
    hover: { mode: null },
    elements: {
        arc: {
            borderWidth: 0.1,
            borderColor: '#000',
        },
        center: {
            text: '',
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: false,
};
const defaultData = {
    datasets: [],
};

const PieChart = React.memo(
    ({ classes, datasets, text, className, imgClassName }) => {
        Chart.pluginService.register({ beforeDraw });

        const [data, setData] = useState(defaultData);
        const [options, setOptions] = useState(defaultOptions);
        const [imageURL, setImageURL] = useState(null);

        useEffect(() => {
            setData({ datasets });
            setOptions({ elements: { center: { text } } });
        }, [datasets, text]);

        const chartRef = useRef(null);
        useEffect(() => {
            const base64Image = chartRef.current.chartInstance.toBase64Image();
            setImageURL(base64Image);
        }, [chartRef, data, options]);

        return (
            <>
                <div className={`${classes.chartWrapper} ${className}`}>
                    {text ? (
                        <Doughnut
                            data={data}
                            options={options}
                            ref={chartRef}
                        />
                    ) : (
                        <Pie data={data} options={options} ref={chartRef} />
                    )}
                </div>
                {imageURL && (
                    <img
                        src={imageURL}
                        className={`${classes.chartImg} ${imgClassName}`}
                        alt="chart"
                    />
                )}
            </>
        );
    },
);

PieChart.defaultProps = {
    text: null,
    className: '',
    imgClassName: '',
};
PieChart.propTypes = {
    datasets: PropTypes.arrayOf(
        PropTypes.shape({
            backgroundColor: PropTypes.arrayOf(PropTypes.string).isRequired,
            data: PropTypes.arrayOf(PropTypes.number).isRequired,
        }).isRequired,
    ).isRequired,
    text: PropTypes.string,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    imgClassName: PropTypes.string,
};

export default withStyles(styles)(PieChart);
