import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import { styles } from './styles';

const Button = ({ classes, children, onClick, withIcon }) => (
    <button type="button" className={classes.root} onClick={onClick}>
        {children}
        {withIcon && <ArrowRightIcon className={classes.icon} color="#fff" />}
    </button>
);

Button.defaultProps = {
    withIcon: true,
    onClick: () => {},
};
Button.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    withIcon: PropTypes.bool,
};

export default withStyles(styles)(Button);
