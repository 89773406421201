import axios from 'axios';
import { get as storageGet } from './storage';

const baseURL = process.env.REACT_APP_NG_BACKEND_API_URL;

const restClient = axios.create();

restClient.interceptors.request.use(
    (config) => {
        const token = storageGet('token');
        if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`;
        }
        // eslint-disable-next-line no-param-reassign
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

const request = {
    get: ({ id, path, params }) => {
        const url = id ? `${baseURL}/${path}/${id}` : `${baseURL}/${path}`;
        return restClient({
            url,
            method: 'GET',
            params,
        });
    },
    post: ({ path, params, data }) => {
        const url = `${baseURL}/${path}`;
        return restClient({
            url,
            method: 'POST',
            params,
            data,
        });
    },
    delete: ({ id, path }) => {
        const url = `${baseURL}/${path}/${id}`;
        return restClient({
            url,
            method: 'DELETE',
        });
    },
};

export { request };
