import { Modal, withStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import TimesIcon from '../../../../components/Icons/TimesIcon';
import Title from '../../../../components/Title';
import { styles } from './styles';

const AddEmployeeFeedbackModal = ({
    isOpen,
    handleClose,
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    isValid,
    dirty,
    errors,
}) => {
    const { t } = useTranslation('translation');
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();
    const CyanRadio = withStyles({
        root: {
            fontSize: '3.9vw',
            '&$checked': {
                color: '#0A96BE',
            },
            color: isLightMode ? '#0E1A24' : '#FAFDFF',
        },
        checked: {},
    })((props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Radio {...props} />
    ));

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <div className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.headerWrapper}>
                        <TimesIcon
                            className={classes.closeIcon}
                            onClick={handleClose}
                            color="#0A96BE"
                        />
                        <div className={classes.header}>
                            <Title>{t('addEmployee')}</Title>
                        </div>
                    </div>
                    <div className={classes.select}>
                        <InputLabel
                            className={classes.modalTitles}
                            htmlFor="name"
                        >
                            {t('addEmployeeName')}
                        </InputLabel>
                        <TextField
                            id="name"
                            name="name"
                            value={values.name}
                            required
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.nameInput}
                            error={!!errors}
                            helperText={errors.name}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    color: isLightMode ? '#0E1A24' : '#FAFDFF',
                                },
                            }}
                        />
                        <InputLabel
                            className={classes.modalTitles}
                            htmlFor="date"
                        >
                            {t('addEmployeeFeedbackDate')}
                        </InputLabel>
                        <TextField
                            id="date"
                            name="date"
                            type="date"
                            value={values.date}
                            onChange={handleChange}
                            margin="normal"
                            className={classes.dateField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    color: isLightMode ? '#0E1A24' : '#FAFDFF',
                                },
                            }}
                        />
                        <RadioGroup
                            name="frequency"
                            className={classes.radioGroup}
                            value={values.frequency}
                            onChange={handleChange}
                        >
                            <FormControlLabel
                                classes={{ label: classes.radioText }}
                                value="monthly"
                                control={<CyanRadio />}
                                label={t('monthlyFeedback')}
                            />
                            <FormControlLabel
                                classes={{ label: classes.radioText }}
                                value="quarterly"
                                control={<CyanRadio />}
                                label={t('quarterlyFeedback')}
                            />
                            <FormControlLabel
                                classes={{ label: classes.radioText }}
                                value="semiannually"
                                control={<CyanRadio />}
                                label={t('semiannuallyFeedback')}
                            />
                            <FormControlLabel
                                classes={{ label: classes.radioText }}
                                value="other"
                                control={<CyanRadio />}
                                label={t('usersChoice')}
                            />
                        </RadioGroup>
                        <Button
                            classes={{ root: classes.submitButton }}
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!(dirty && isValid)}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

AddEmployeeFeedbackModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    values: PropTypes.shape({
        name: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        frequency: PropTypes.string.isRequired,
    }).isRequired,
    isValid: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
};

export default AddEmployeeFeedbackModal;
