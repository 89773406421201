import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';
import ArrowUpIcon from '../../../../components/Icons/ArrowUpIcon';
import CircleIcon from '../../../../components/Icons/CircleIcon';
import { styles } from './styles';

const UsageBadge = ({ classes, className, MainIcon, percentage, title }) => {
    const isPositive = percentage >= 0;
    return (
        <div className={`${classes.root} ${className}`}>
            <div className={classes.iconsOuterWrapper}>
                <div className={classes.iconsInnerWrapper}>
                    {!!percentage && (
                        <div className={classes.arrowIconWrapper}>
                            {isPositive ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </div>
                    )}
                    <MainIcon />
                    <div className={classes.percentageWrapper}>
                        <CircleIcon
                            className={classes.circleIcon}
                            color={isPositive ? '#6FCF97' : '#B82C54'}
                        />
                        <div className={classes.percentage}>
                            {`${isPositive ? '+' : ''}${percentage}%`}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.title}>{title}</div>
        </div>
    );
};

UsageBadge.defaultProps = {
    className: '',
};
UsageBadge.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    MainIcon: PropTypes.func.isRequired,
    percentage: PropTypes.number.isRequired,
    title: PropTypes.node.isRequired,
};

export default withStyles(styles)(UsageBadge);
