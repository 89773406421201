import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LineChart from '../../../../components/LineChart';
import Title from '../../../../components/Title';
import { styles } from './styles';

const ExecutiveProgressLineChart = React.memo(
    ({ classes, data, title, subTitle }) => {
        const { t } = useTranslation('translation');
        const sortedPerformanceChart = data.sort(
            (prev, next) => prev.week - next.week,
        );

        const chartData = {
            labels: sortedPerformanceChart.map((_, index) => `W${index + 1}`),
            datasets: [
                {
                    label: t('yourDepartment'),
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: '#B82C54',
                    data: sortedPerformanceChart.map(
                        (item) => item.averageDepartment,
                    ),
                    pointRadius: 0,
                    lineTension: 0,
                    borderWidth: 4,
                },
                {
                    label: t('otherDepartments'),
                    backgroundColor: 'rgb(0, 0, 0, 0)',
                    borderColor: '#F2C94C',
                    data: sortedPerformanceChart.map(
                        (item) => item.averageCompany,
                    ),
                    pointRadius: 0,
                    lineTension: 0,
                    borderWidth: 4,
                },
            ],
        };

        return (
            <div>
                <Title>{title}</Title>
                <div className={classes.subTitle}>{subTitle}</div>
                <LineChart data={chartData} />
            </div>
        );
    },
);

ExecutiveProgressLineChart.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
};

export default withStyles(styles)(ExecutiveProgressLineChart);
