function put(key, value) {
    localStorage.setItem(key, value);
}

function get(key) {
    return localStorage.getItem(key);
}

function clear() {
    localStorage.clear();
}

export { put, get, clear };
