import PropTypes from 'prop-types';
import React from 'react';

const BestDepartmentBadge = ({ className, size }) => (
    <svg
        className={className}
        width={size}
        viewBox="0 0 92.28 106.41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M92.22,36.74c-.74-3.26-7.81-4.29-9.22-7.23s2.14-9.15.09-11.72-8.87-.45-11.45-2.51-2-9.17-5-10.61-8.15,3.45-11.4,2.7S49.51,0,46.14,0s-6,6.66-9.1,7.38-8.46-4.12-11.4-2.7-2.42,8.56-5,10.61S11.26,15.22,9.2,17.8s1.53,8.73.09,11.72-8.48,4-9.23,7.23,5.19,7.13,5.19,10.5S-.65,54.61.06,57.76,7.87,62.05,9.29,65,7.15,74.14,9.2,76.71s8.87.45,11.45,2.51,2,9.17,5,10.61C28.05,91,32,87.93,35.13,87.2l.18,19.21L46.47,99l10.72,7.22-.17-19c3.16.66,7.18,3.84,9.63,2.66,3-1.44,2.42-8.56,5-10.61s9.39.07,11.45-2.51S81.56,68,83,65s8.48-4,9.22-7.23S87,50.62,87,47.25,92.93,39.89,92.22,36.74Z"
            fill="#0895be"
        />
        <path
            d="M63.28,75.23A2.62,2.62,0,0,1,62,74.92L46.39,66.69a.55.55,0,0,0-.5,0L30.24,74.92a2.65,2.65,0,0,1-3.85-2.79l3-17.44a.54.54,0,0,0-.15-.47L16.56,41.88A2.66,2.66,0,0,1,18,37.35l17.5-2.54a.55.55,0,0,0,.4-.29l7.83-15.87a2.63,2.63,0,0,1,2.38-1.47h0a2.63,2.63,0,0,1,2.38,1.47l7.83,15.87a.53.53,0,0,0,.39.29l17.51,2.54a2.66,2.66,0,0,1,1.47,4.53L63.05,54.22a.54.54,0,0,0-.15.47l3,17.44a2.65,2.65,0,0,1-2.61,3.1ZM46.14,64.51a2.66,2.66,0,0,1,1.23.3L63,73a.51.51,0,0,0,.56,0,.51.51,0,0,0,.21-.51l-3-17.44a2.66,2.66,0,0,1,.76-2.35L74.24,40.35a.51.51,0,0,0,.13-.54.52.52,0,0,0-.42-.36L56.44,36.91a2.69,2.69,0,0,1-2-1.45L46.61,19.59a.53.53,0,0,0-.94,0L37.84,35.46a2.69,2.69,0,0,1-2,1.45L18.33,39.45a.52.52,0,0,0-.42.36.51.51,0,0,0,.13.54L30.71,52.7a2.66,2.66,0,0,1,.76,2.35l-3,17.44a.53.53,0,0,0,.77.55L44.9,64.81A2.73,2.73,0,0,1,46.14,64.51Z"
            fill="white"
        />
        <path
            d="M38.07,62.44a1.82,1.82,0,0,1-1.79-2.12l1.38-8-5.84-5.7a1.81,1.81,0,0,1,1-3.09L40.9,42.3,44.52,35a1.78,1.78,0,0,1,1.62-1h0a1.8,1.8,0,0,1,1.62,1l3.62,7.32,8.08,1.18a1.81,1.81,0,0,1,1,3.09l-5.85,5.7,1.39,8a1.82,1.82,0,0,1-2.63,1.91l-7.23-3.81-7.23,3.81A1.83,1.83,0,0,1,38.07,62.44Zm8.07-6.18a1.77,1.77,0,0,1,.84.21l6.81,3.58-1.3-7.59a1.84,1.84,0,0,1,.52-1.6l5.52-5.37-7.62-1.11a1.77,1.77,0,0,1-1.36-1l-3.41-6.9-3.41,6.9a1.77,1.77,0,0,1-1.36,1l-7.62,1.11,5.51,5.37a1.81,1.81,0,0,1,.53,1.6l-1.31,7.59,6.82-3.58A1.77,1.77,0,0,1,46.14,56.26Zm-.28-20.34Z"
            fill="white"
        />
    </svg>
);

BestDepartmentBadge.defaultProps = {
    className: '',
    size: 70,
};
BestDepartmentBadge.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
};

export default BestDepartmentBadge;
