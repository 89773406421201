import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import Button from '../../../../components/Button';
import StarIconSm from '../../../../components/Icons/StarIconSm';
import StarIconSmFill from '../../../../components/Icons/StartIconSmFill';
import Paper from '../../../../components/Paper';
import {
    addSessionData,
    fetchEmployeeFeedbacksList,
} from '../../../../store/modules/employeeFeedbacks/employeeFeedbacks.thunks';
import { styles } from './styles';

const SessionDataTalk = React.memo(() => {
    const { t } = useTranslation('translation');
    const history = useHistory();
    const { feedbackId: currentFeedbackId } = useParams();
    const { sessionId: currentSessionId } = useParams();
    const dispatch = useDispatch();
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();
    useEffect(() => {
        dispatch(fetchEmployeeFeedbacksList());
    }, [dispatch]);

    const sessionData = useSelector((state) => {
        const feedback = state.employeeFeedbacks.employeeFeedbacksList.find(
            (feedbackElement) => currentFeedbackId === feedbackElement.id,
        );
        if (!feedback) return null;
        const feedbackSession = feedback.sessions.find(
            (session) => currentSessionId === session.id,
        );
        if (!feedbackSession) return null;

        const neededSessionData = feedbackSession.sessionData.find(
            (data) => data.sessionType === 'followup',
        );

        if (!neededSessionData) return null;
        const { date, rating } = neededSessionData;
        return {
            ...neededSessionData.data,
            date: moment(date).format('YYYY-MM-DD'),
            rating,
        };
    });

    const initialState = useMemo(
        () => ({
            reaction: '',
            proven: '',
            notProven: '',
            changes: '',
            date: moment().format('YYYY-MM-DD'),
            rating: 0,
        }),
        [],
    );

    const textFields = useMemo(
        () => ({
            reaction: t('reaction'),
            proven: t('proven'),
            notProven: t('notProven'),
            changes: t('changes'),
        }),
        [t],
    );

    const doSubmit = useCallback(
        (values) => {
            const {
                reaction,
                proven,
                notProven,
                changes,
                date,
                rating,
            } = values;
            const body = {
                feedbackSessionId: currentSessionId,
                data: JSON.stringify({ reaction, proven, notProven, changes }),
                sessionType: 'followup',
                date,
                rating: Number(rating),
            };
            dispatch(addSessionData(body));
            history.push(
                `/feedbacks/${currentFeedbackId}/sessions/${currentSessionId}`,
            );
        },
        [dispatch, history, currentFeedbackId, currentSessionId],
    );
    return (
        <Container className={classes.root} spacing={0}>
            <Formik
                onSubmit={doSubmit}
                initialValues={sessionData || initialState}
                enableReinitialize
            >
                {({
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    handleSubmit,
                }) => (
                    <form className={classes.select}>
                        <Paper className={classes.paper}>
                            <div className={classes.title}>
                                {t('sessionDataFollowupTitle')}
                            </div>
                            <div>
                                <div className={classes.formTitles}>
                                    {t('sessionDataFollowupFirstSubtitle')}
                                </div>
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.reaction}
                                </InputLabel>
                                <TextField
                                    id="reaction"
                                    name="reaction"
                                    value={values.reaction}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.proven}
                                </InputLabel>
                                <TextField
                                    id="proven"
                                    name="proven"
                                    value={values.proven}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.notProven}
                                </InputLabel>
                                <TextField
                                    id="notProven"
                                    name="notProven"
                                    value={values.notProven}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                            </div>
                            <div>
                                <div className={classes.formTitles}>
                                    {t('sessionDataFollowupSecondSubtitle')}
                                </div>
                                <Box
                                    component="fieldset"
                                    mb={3}
                                    borderColor="transparent"
                                    className={classes.ratingBox}
                                >
                                    <Rating
                                        name="rating"
                                        value={Number(values.rating)}
                                        onChange={handleChange}
                                        icon={<StarIconSmFill width={36} />}
                                        emptyIcon={<StarIconSm width={36} />}
                                    />
                                </Box>
                            </div>
                            <div>
                                <div className={classes.formTitles}>
                                    {t('sessionDataFollowupThirdSubtitle')}
                                </div>
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.changes}
                                </InputLabel>
                                <TextField
                                    id="changes"
                                    name="changes"
                                    value={values.changes}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                            </div>
                            <div>
                                <InputLabel
                                    className={classes.formTitles}
                                    htmlFor="date"
                                >
                                    {t('sessionDataFollowupFourthSubtitle')}
                                </InputLabel>
                                <TextField
                                    id="date"
                                    name="date"
                                    type="date"
                                    value={values.date}
                                    onChange={handleChange}
                                    margin="normal"
                                    className={classes.input}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                />
                            </div>
                            <Button
                                classes={{ root: classes.submitButton }}
                                type="submit"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                {t('saveOverview')}
                            </Button>
                        </Paper>
                    </form>
                )}
            </Formik>
        </Container>
    );
});

export default SessionDataTalk;
