import EmployeeFeedbackService from '../../../services/employeeFeedbackService';
import {
    fetchEmployeeFeedbacksAction,
    fetchEmployeeFeedbacksSuccessAction,
    fetchEmployeeFeedbacksFailureAction,
    createEmployeeFeedbackAction,
    createEmployeeFeedbackSuccessAction,
    createEmployeeFeedbackFailureAction,
    deleteEmployeeFeedbackAction,
    deleteEmployeeFeedbackSuccessAction,
    deleteEmployeeFeedbackFailureAction,
    createFeedbackSessionAction,
    createFeedbackSessionSuccessAction,
    createFeedbackSessionFailureAction,
    createOrUpdateSessionDataAction,
    createOrUpdateSessionDataSuccessAction,
    createOrUpdateSessionDataFailureAction,
    sendSessionDataByEmailAction,
    sendSessionDataByEmailSuccessAction,
    sendSessionDataByEmailFailureAction,
} from './employeeFeedbacks.actions';

export function fetchEmployeeFeedbacksList() {
    return async (dispatch) => {
        dispatch(fetchEmployeeFeedbacksAction());
        try {
            const response = await EmployeeFeedbackService.getEmployeeFeedbacksList();

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(fetchEmployeeFeedbacksSuccessAction(response));
        } catch (err) {
            dispatch(fetchEmployeeFeedbacksFailureAction(err.message));
        }
    };
}

export function addEmployeeFeedback(data) {
    return async (dispatch) => {
        dispatch(createEmployeeFeedbackAction());
        try {
            const response = await EmployeeFeedbackService.createEmployeeFeedback(
                data,
            );

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(createEmployeeFeedbackSuccessAction(response));
        } catch (err) {
            dispatch(createEmployeeFeedbackFailureAction(err.message));
        }
    };
}

export function removeEmployeeFeedback(id) {
    return async (dispatch) => {
        dispatch(deleteEmployeeFeedbackAction());
        try {
            const response = await EmployeeFeedbackService.deleteEmployeeFeedback(
                id,
            );

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(deleteEmployeeFeedbackSuccessAction(response.id));
        } catch (err) {
            dispatch(deleteEmployeeFeedbackFailureAction(err.message));
        }
    };
}

export function addFeedbackSession(data) {
    return async (dispatch) => {
        dispatch(createFeedbackSessionAction());
        try {
            const response = await EmployeeFeedbackService.createFeedbackSession(
                data,
            );

            if (!response) {
                throw new Error('Missing response from API');
            }
            dispatch(createFeedbackSessionSuccessAction(response));
        } catch (err) {
            dispatch(createFeedbackSessionFailureAction(err.message));
        }
    };
}

export function addSessionData(data) {
    return async (dispatch) => {
        dispatch(createOrUpdateSessionDataAction());
        try {
            const response = await EmployeeFeedbackService.createOrUpdateSessionData(
                data,
            );

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(createOrUpdateSessionDataSuccessAction(response));
        } catch (err) {
            dispatch(createOrUpdateSessionDataFailureAction(err.message));
        }
    };
}

export function sendSessionDataByEmail(data) {
    return async (dispatch) => {
        dispatch(sendSessionDataByEmailAction());
        try {
            const response = await EmployeeFeedbackService.sendSessionDataByEmail(
                data,
            );

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(sendSessionDataByEmailSuccessAction(response));
        } catch (err) {
            dispatch(sendSessionDataByEmailFailureAction(err.message));
        }
    };
}
