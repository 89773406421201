import { Modal } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
    SwipeableList,
    SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button';
import ArrowRightIcon from '../../../../components/Icons/ArrowRightIcon';
import RefreshIcon from '../../../../components/Icons/RefreshIcon';
import TimesIcon from '../../../../components/Icons/TimesIcon';
import Paper from '../../../../components/Paper';
import Swipe from '../../../../components/Swipe';
import Title from '../../../../components/Title';
import { removeEmployeeFeedback } from '../../../../store/modules/employeeFeedbacks/employeeFeedbacks.thunks';
import { styles } from './styles';

const EmployeeFeedbacksCard = React.memo(({ feedback }) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [modalIsOpened, setModalIsOpened] = useState(false);
    const handleModalClose = useCallback(() => setModalIsOpened(false), [
        setModalIsOpened,
    ]);
    const handleModalOpen = useCallback(() => setModalIsOpened(true), [
        setModalIsOpened,
    ]);
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();
    const date = moment(feedback.sessions[0].date)
        .tz('Europe/Berlin')
        .format('MM/DD/YYYY');

    const frequency = useMemo(
        () => ({
            monthly: t('monthly'),
            quarterly: t('quarterly'),
            semiannually: t('semiannually'),
            other: t('other'),
        }),
        [t],
    );

    const swipeRightOptions = useCallback(
        () => ({
            content: <Swipe icon={<DeleteForeverIcon />} label={t('delete')} />,
            action: () => handleModalOpen(),
        }),
        [handleModalOpen, t],
    );

    const employeeFeedbackId = feedback.id;
    const handleSubmit = useCallback(() => {
        dispatch(removeEmployeeFeedback(employeeFeedbackId));
        handleModalClose();
    }, [handleModalClose, dispatch, employeeFeedbackId]);

    return (
        <Container>
            <Modal open={modalIsOpened} onClose={handleModalClose}>
                <div className={classes.modalRoot}>
                    <div className={classes.content}>
                        <div className={classes.headerWrapper}>
                            <TimesIcon
                                className={classes.closeIcon}
                                onClick={handleModalClose}
                                color="#0A96BE"
                                width={20}
                            />
                            <div className={classes.header}>
                                <Title classes={{ title: classes.title }}>
                                    {t('deleteFeedback')}
                                </Title>
                            </div>
                        </div>
                        <div className={classes.buttons}>
                            <Button
                                classes={{ root: classes.submitButton }}
                                type="submit"
                                variant="contained"
                                onClick={handleSubmit}
                                withIcon={false}
                            >
                                {t('yes')}
                            </Button>
                            <Button
                                classes={{ root: classes.submitButton }}
                                type="submit"
                                variant="contained"
                                onClick={handleModalClose}
                                withIcon={false}
                            >
                                {t('no')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <SwipeableList>
                <SwipeableListItem swipeRight={swipeRightOptions()}>
                    <Link
                        to={`/feedbacks/${employeeFeedbackId}/sessions/`}
                        className={classes.link}
                    >
                        <Paper className={classes.root}>
                            <RefreshIcon>
                                {frequency[feedback.frequency]}
                            </RefreshIcon>
                            <Container className={classes.userData}>
                                <div className={classes.name}>
                                    {feedback.name}
                                </div>
                                <div className={classes.date}>
                                    {t('nextConversation')} {date}
                                </div>
                            </Container>
                            <ArrowRightIcon />
                        </Paper>
                    </Link>
                </SwipeableListItem>
            </SwipeableList>
        </Container>
    );
});

EmployeeFeedbacksCard.propTypes = {
    feedback: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        sessions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ),
        frequency: PropTypes.string.isRequired,
        createdAt: PropTypes.string,
    }).isRequired,
};

export default EmployeeFeedbacksCard;
