import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '../../components/Paper';
import { fetchDashboardData } from '../../store/modules/dashboard/dashboard.thunks';
// import { Badge } from './components/Badge';
import { LevelChart } from './components/LevelChart';
import { ProgressChart } from './components/ProgressChart';
import { styles } from './styles';

const DashboardPage = React.memo(() => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchDashboardData());
    }, [dispatch]);
    const userType = useSelector((state) => state.auth.userType);
    const {
        completed,
        // badges,
        // scoreSummary,
        isLoadingDashboardData,
    } = useSelector((state) => state.dashboard);
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const classes = makeStyles(() => styles(useTheme(), isLightMode))();

    return (
        <Container component="main" className={classes.root}>
            {userType === 'TRIAL' && (
                <Alert severity="warning" className={classes.pageWarningLabel}>
                    {t('warningTrialUsers')}
                </Alert>
            )}
            {completed && userType === 'REGULAR' && (
                <Alert severity="success" className={classes.pageWarningLabel}>
                    {t('warningRegularUsers')}
                </Alert>
            )}
            <div className={classes.playoffSection}>
                {!isLoadingDashboardData && (
                    <Paper className={classes.progressChartPaper} elevation={4}>
                        <ProgressChart />
                        <div className={classes.progressChartDescription}>
                            <Typography
                                className={
                                    classes.progressChartDescriptionTitle
                                }
                            >
                                {t('progressChartDescriptionTitle')}
                            </Typography>
                            <Typography
                                className={
                                    classes.progressChartDescriptionSubTitle
                                }
                            >
                                {t('progressChartDescriptionSubTitle')}
                            </Typography>
                        </div>
                    </Paper>
                )}
                {!isLoadingDashboardData && (
                    <Paper className={classes.levelChartPaper} elevation={4}>
                        <Typography className={classes.levelChartTitle}>
                            {t('levelChartTitle')}
                        </Typography>
                        <LevelChart />
                    </Paper>
                )}
                {/* <Paper className={classes.badgesPaper} elevation={4}> */}
                {/*    <Typography className={classes.badgesTitle}> */}
                {/*        {t('badgesTitle')} */}
                {/*    </Typography> */}
                {/*    {badges.length ? ( */}
                {/*        <div className={classes.badgeList}> */}
                {/*            {badges.map(({ badgeName, base64BadgeImage }) => ( */}
                {/*                <Badge */}
                {/*                    key={badgeName} */}
                {/*                    title={badgeName} */}
                {/*                    base64={base64BadgeImage} */}
                {/*                /> */}
                {/*            ))} */}
                {/*        </div> */}
                {/*    ) : ( */}
                {/*        <Typography */}
                {/*            variant="subtitle1" */}
                {/*            className={classes.subtitle} */}
                {/*        > */}
                {/*            {t('dashboardSubtitle')} */}
                {/*        </Typography> */}
                {/*    )} */}
                {/* </Paper> */}
                {/* <Paper className={classes.scoresWrapper}> */}
                {/*    <Typography className={classes.scoresTitle}> */}
                {/*        {t('scoresTitle')} */}
                {/*    </Typography> */}
                {/*    <div */}
                {/*        key={scoreSummary.learn + scoreSummary.engagement} */}
                {/*        className={classes.scoresMetricWrapper} */}
                {/*    > */}
                {/*        <div> */}
                {/*            <div className={classes.scoresMetricLearn}> */}
                {/*                Learn: */}
                {/*            </div> */}
                {/*            <div className={classes.scoresMetricEngagement}> */}
                {/*                Engagement: */}
                {/*            </div> */}
                {/*        </div> */}
                {/*        <div> */}
                {/*            <div className={classes.scoresMetricLearn}> */}
                {/*                {scoreSummary.learn} */}
                {/*            </div> */}
                {/*            <div className={classes.scoresMetricEngagement}> */}
                {/*                {scoreSummary.engagement} */}
                {/*            </div> */}
                {/*        </div> */}
                {/*    </div> */}
                {/* </Paper> */}
            </div>
        </Container>
    );
});

export default DashboardPage;
