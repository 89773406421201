import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { styles } from './styles';

const Title = ({ classes, className, children }) => (
    <div className={`${classes.title} ${className}`}>{children}</div>
);

Title.defaultProps = {
    className: '',
};
Title.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(Title);
