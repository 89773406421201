import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { FeedbackSessionsCard } from '../FeedbackSessionsCard';
import { styles } from './styles';

const FeedbackSessionsList = ({ classes, sessions }) => {
    return sessions.map((session) => (
        <FeedbackSessionsCard
            key={Math.random()}
            className={classes.listItem}
            session={session}
        />
    ));
};

FeedbackSessionsList.propTypes = {
    classes: PropTypes.object.isRequired,
    sessions: PropTypes.array.isRequired,
};

export default withStyles(styles)(FeedbackSessionsList);
