export const styles = (theme, isLightMode) => ({
    root: {
        padding: '60px 24px 24px 24px',
        [theme.breakpoints.up(theme.sizes.mobileThreshold)]: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '50px 20px 40px 0',
        padding: '0px 35px',
    },
    button: {
        backgroundColor: 'Transparent',
        backgroundRepeat: 'no-repeat',
        border: 'none',
        cursor: 'pointer',
        overflow: 'hidden',
        outline: 'none',
    },
});
