export const styles = (theme, isLightMode) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '11px 11px',
        marginBottom: '17px',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    main: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    titleWrapper: {
        maxWidth: '90%',
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
    },
    subTitle: {
        fontSize: 14,
    },
    progress: {
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.blue,
    },
    rateButton: {
        marginTop: '5px',
        width: '100%',
    },
    rateLink: {
        width: '70%',
        alignSelf: 'flex-start',
    },
});
