import { request } from '../../../utils/request';
import {
    fetchEmployeeOKRsAction,
    fetchEmployeeOKRsSuccessAction,
    fetchEmployeeOKRsFailureAction,
} from './employeeOKRs.actions';

export function fetchEmployeeOKRs() {
    return async (dispatch) => {
        dispatch(fetchEmployeeOKRsAction());
        try {
            const response = await request.get({
                path: 'statistics/tools/okr',
            });

            if (!response) {
                throw new Error('Missing response from API');
            }
            dispatch(fetchEmployeeOKRsSuccessAction(response.data));
        } catch (err) {
            dispatch(fetchEmployeeOKRsFailureAction(err.message));
        }
    };
}
