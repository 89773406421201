import { withStyles } from '@material-ui/core/styles';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { styles } from './styles';

const LineChart = ({ classes, data }) => {
    const [canvasEl, setCanvasEl] = useState();

    useEffect(() => {
        if (canvasEl && data) {
            const ctx = canvasEl.getContext('2d');
            new Chart(ctx, {
                type: 'line',
                data,
                options: {
                    legend: {
                        display: false,
                        position: 'bottom',
                        labels: {},
                    },
                    elements: {
                        center: {
                            text: '',
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                                ticks: {
                                    callback: (value, i) =>
                                        i % 2 ? value : '',
                                },
                            },
                        ],
                        xAxes: [
                            {
                                gridLines: {
                                    display: false,
                                },
                            },
                        ],
                    },
                },
            });
        }
    }, [canvasEl, data]);

    return (
        <div className={classes.wrapper}>
            <canvas
                id="performance-chart"
                height="180"
                ref={(el) => setCanvasEl(el)}
            >
                Line Chart
            </canvas>
            <div className={classes.legendsWrapper}>
                {data.datasets.map(({ label, borderColor }) => (
                    <div key={label} className={classes.legend}>
                        <div
                            style={{ backgroundColor: borderColor }}
                            className={classes.legendIcon}
                        />
                        <div className={classes.legendLabel}>{label}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

LineChart.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default withStyles(styles)(LineChart);
