import PropTypes from 'prop-types';
import React from 'react';

const ArrowRightIcon = ({ className, color }) => (
    <svg
        className={className}
        width="10"
        height="19"
        viewBox="0 0 10 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M1 18L8 9.5L1 1" stroke={color} strokeWidth="2" />
    </svg>
);

ArrowRightIcon.defaultProps = {
    className: '',
    color: '#0A96BE',
};
ArrowRightIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

export default ArrowRightIcon;
