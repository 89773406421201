import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['navigator'],
        },
        react: {
            useSuspense: false,
        },
        fallbackLng: 'en',
        ns: 'translation',
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false,
        },
        debug: false,
    });
export default i18n;
