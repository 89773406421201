import {
    FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_FAILURE_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_SUCCESS_ACTION,
} from './employeeDepartmentEngagement.action-types';

export const fetchEmployeeDepartmentEngagementAction = () => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_ACTION,
});

export const fetchEmployeeDepartmentEngagementSuccessAction = (
    employeeDepartmentEngagementData,
) => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_SUCCESS_ACTION,
    employeeDepartmentEngagementData,
});

export const fetchEmployeeDepartmentEngagementFailureAction = (error) => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_FAILURE_ACTION,
    error,
});
