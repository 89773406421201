import PropTypes from 'prop-types';
import React from 'react';

const CircleIcon = ({ className, color }) => (
    <svg
        className={className}
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="21" cy="21" r="21" fill={color} />
    </svg>
);

CircleIcon.defaultProps = {
    className: '',
    color: 'green',
};
CircleIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

export default CircleIcon;
