export const styles = (theme, isLightMode) => ({
    root: {
        padding: '60px 24px 24px 24px',
        top: '0px',
        bottom: '0px',
        maxWidth: 1000,
        height: 'auto',
        position: 'fixed',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    header: {
        margin: '32px 20px 0 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    subTitle: {
        marginBottom: 23,
        marginTop: 3,
        fontSize: 18,
    },
    alterText: {
        margin: '0 20px',
    },
    chooseButton: {
        marginTop: 0,
        marginBottom: '23px',
        width: '75%',
        height: '60%',
    },
});
