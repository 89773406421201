import { Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PieChart from '../../components/PieChart/PieChart';
import Title from '../../components/Title';
import { KeyResult } from './components/KeyResult';
import { styles } from './styles';

const OKRDetails = () => {
    const { t } = useTranslation('translation');
    const { objective } = useParams();

    const employeeOKRsList = useSelector(
        (state) => state.employeeOKRs.employeeOKRsList,
    );
    let selectedObjective;
    let objectiveOrder = '';
    employeeOKRsList.forEach((employeeOKR, i) => {
        if (employeeOKR.objective === objective) {
            selectedObjective = employeeOKR;
            objectiveOrder = i + 1;
        }
    });

    const progress = selectedObjective
        ? Math.floor(selectedObjective.averageProgress)
        : 0;
    const total = 100;
    const datasets = [
        {
            backgroundColor: ['#B82C54', '#56CCF2'],
            data: [progress, total - progress],
        },
    ];
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();

    return (
        <Container className={classes.root} spacing={0}>
            <div className={classes.innerWrapper}>
                <div className={classes.titleWrapper}>
                    <Title>
                        {t('objective')} #{objectiveOrder}
                    </Title>
                    <div className={classes.subTitle}>{objective}</div>
                </div>
                <div className={classes.chartWrapper}>
                    <PieChart
                        datasets={datasets}
                        text={`${progress}%`}
                        className={classes.chart}
                        imgClassName={classes.chartImg}
                    />
                </div>
                <div className={classes.keyResults}>
                    {selectedObjective &&
                        selectedObjective.keyResults.map((keyResult, i) => (
                            <KeyResult
                                rateLink={keyResult.rateLink}
                                enabled={selectedObjective.ratingEnabled}
                                key={Math.random()}
                                title={keyResult.title}
                                progress={keyResult.rating}
                                order={i + 1}
                            />
                        ))}
                </div>
            </div>
        </Container>
    );
};

export default OKRDetails;
