import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { get as storageGet } from '../utils/storage';

const authLink = setContext(async (_, { headers }) => {
    const token = await storageGet('token');

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_BACKEND_API_URL}/api`,
});

const defaultOptions = {
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const graphqlClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: false,
    }),
    defaultOptions,
});

export default graphqlClient;
