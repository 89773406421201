import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import Carousel from '../../components/Carousel';
import BestDepartmentBadge from '../../components/Icons/BestDepartmentBadge';
import BookIcon from '../../components/Icons/BookIcon';
import ClockIcon from '../../components/Icons/ClockIcon';
import Paper from '../../components/Paper';
import Title from '../../components/Title';
import { fetchEmployeeDepartmentEngagement } from '../../store/modules/employeeDepartmentEngagement/employeeDepartmentEngagement.thunks';
import {
    fetchBestDepartment,
    fetchEmployeeDepartmentModulesPerformance,
    fetchEmployeeDepartmentPlayoffPerformance,
} from '../../store/modules/employeePerformance/employeePerformance.thunks';
import ExecutiveProgressLineChart from './components/ExecutiveProgressLineChart';
import UsageBadge from './components/UsageBadge';
import { styles } from './styles';

const DepartmentProgress = React.memo(() => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchEmployeeDepartmentModulesPerformance());
        dispatch(fetchEmployeeDepartmentEngagement());
        dispatch(fetchEmployeeDepartmentPlayoffPerformance());
        dispatch(fetchBestDepartment());
    }, [dispatch]);

    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const classes = makeStyles(() => styles(useTheme(), isLightMode))();

    const {
        employeeDepartmentModulesPerformance,
        isLoadingEmployeeDepartmentModulesPerformance,
        // employeeDepartmentPlayoffPerformance,
        // isLoadingEmployeeDepartmentPlayoffPerformance,
        bestDepartmentName,
        isLoadingBestDepartment,
    } = useSelector((state) => state.employeePerformance);
    const {
        sessionsLastWeek,
        sessionsPercentage,
        articlesLastWeek,
        articlesPercentage,
    } = useSelector((state) => {
        if (
            state.employeeDepartmentEngagement
                .isLoadingEmployeeDepartmentEngagement
        ) {
            return {};
        }
        const {
            employeeDepartmentEngagementData,
        } = state.employeeDepartmentEngagement;
        return {
            sessionsLastWeek:
                employeeDepartmentEngagementData.sessions.lastWeek,
            sessionsPercentage:
                employeeDepartmentEngagementData.sessions.sessionsPercentage,
            articlesLastWeek:
                employeeDepartmentEngagementData.openedArticles.lastWeek,
            articlesPercentage:
                employeeDepartmentEngagementData.openedArticles
                    .articlesPercentage,
        };
    });

    const NoDepartmentModulesDataForDepartment = () => (
        <Container className={classes.alterText}>
            {t('noDepartmentModulesData')}
        </Container>
    );

    // const NoDepartmentPlayoffDataForDepartment = () => (
    //     <Container className={classes.alterText}>
    //         {t('noDepartmentPlayoffData')}
    //     </Container>
    // );

    return (
        <div className={classes.backdrop}>
            <Container className={classes.root}>
                {/* <Carousel className={classes.carousel}> */}
                    <div className={classes.carousel} key="slide1">
                        {!isLoadingEmployeeDepartmentModulesPerformance ? (
                            <Paper className={classes.carouselPaper}>
                                {employeeDepartmentModulesPerformance.length ? (
                                    <ExecutiveProgressLineChart
                                        data={
                                            employeeDepartmentModulesPerformance
                                        }
                                        title={t('slideOneTitle')}
                                        subTitle={t('slideOneSubtitle')}
                                    />
                                ) : (
                                    <NoDepartmentModulesDataForDepartment />
                                )}
                            </Paper>
                        ) : (
                            <div className={classes.spinnerWrapper}>
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                    {/* <div key="slide2">
                        {!isLoadingEmployeeDepartmentPlayoffPerformance ? (
                            <Paper className={classes.carouselPaper}>
                                {employeeDepartmentPlayoffPerformance.length ? (
                                    <ExecutiveProgressLineChart
                                        data={
                                            employeeDepartmentPlayoffPerformance
                                        }
                                        title={t('slideTwoTitle')}
                                        subTitle={t('slideTwoSubtitle')}
                                    />
                                ) : (
                                    <NoDepartmentPlayoffDataForDepartment />
                                )}
                            </Paper>
                        ) : (
                            <div className={classes.spinnerWrapper}>
                                <CircularProgress />
                            </div>
                        )}
                    </div> */}
                {/* </Carousel> */}
                {!isLoadingBestDepartment ? (
                    <div className={classes.bestDepartmentBadgeSection}>
                        <BestDepartmentBadge />
                        <div className={classes.bestDepartmentBadgeText}>
                            <div>
                                <strong>
                                    {bestDepartmentName.bestDepartmentName}
                                </strong>
                            </div>
                            <div>{t('bestDepartmentBadge')}</div>
                        </div>
                    </div>
                ) : (
                    <div />
                )}
                {(articlesPercentage || sessionsPercentage) && (
                    <div className={classes.usageBadgesSection}>
                        <Title>{t('usageBadge')}</Title>
                        <div className={classes.usageBadgesWrapper}>
                            <UsageBadge
                                className={classes.usageBadge}
                                percentage={articlesPercentage}
                                MainIcon={BookIcon}
                                title={
                                    <div>
                                        {t('usageBadgeTitle')}&nbsp;
                                        <span
                                            className={
                                                classes.usageBadgeBoldText
                                            }
                                        >
                                            {articlesLastWeek} {t('article')}
                                        </span>{' '}
                                        {t('read')}
                                    </div>
                                }
                            />
                            <UsageBadge
                                className={classes.usageBadge}
                                percentage={sessionsPercentage}
                                MainIcon={ClockIcon}
                                title={
                                    <div>
                                        <span
                                            className={
                                                classes.usageBadgeBoldText
                                            }
                                        >
                                            {sessionsLastWeek} {t('hours')}
                                        </span>{' '}
                                        {t('spent')}
                                    </div>
                                }
                            />
                        </div>
                    </div>
                )}
            </Container>
        </div>
    );
});

export default DepartmentProgress;
