import {
    FETCH_EMPLOYEE_OKRS_ACTION,
    FETCH_EMPLOYEE_OKRS_SUCCESS_ACTION,
    FETCH_EMPLOYEE_OKRS_FAILURE_ACTION,
} from './employeeOKRs.action-types';

const initialState = {
    isLoadingEmployeeOKRs: false,
    error: null,
    employeeOKRsList: [],
};

export function employeeOKRs(state = initialState, action) {
    switch (action.type) {
        case FETCH_EMPLOYEE_OKRS_ACTION: {
            return {
                ...state,
                isLoadingEmployeeOKRs: true,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_OKRS_SUCCESS_ACTION: {
            return {
                ...state,
                employeeOKRsList: action.employeeOKRsList,
                isLoadingEmployeeOKRs: false,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_OKRS_FAILURE_ACTION: {
            return {
                ...state,
                employeeOKRsList: [],
                isLoadingEmployeeOKRs: false,
                error: action.error,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
