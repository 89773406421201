import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { auth } from './modules/auth/auth.reducer';
import { dashboard } from './modules/dashboard/dashboard.reducer';
import { employeeDepartmentEngagement } from './modules/employeeDepartmentEngagement/employeeDepartmentEngagement.reducer';
import { employeeFeedbacks } from './modules/employeeFeedbacks/employeeFeedbacks.reducer';
import { employeeOKRs } from './modules/employeeOKRs/employeeOKRs.reducer';
import { employeePerformance } from './modules/employeePerformance/employeePerformance.reducer';

const history = createBrowserHistory();

export const rootReducer = combineReducers({
    auth,
    dashboard,
    employeePerformance,
    employeeDepartmentEngagement,
    employeeOKRs,
    employeeFeedbacks,
    history,
});
