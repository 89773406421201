import {
    FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_SUCCESS_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_FAILURE_ACTION,
} from './employeeDepartmentEngagement.action-types';

const initialState = {
    isLoadingEmployeeDepartmentEngagement: true,
    error: null,
    employeeDepartmentEngagementData: {},
};

export function employeeDepartmentEngagement(state = initialState, action) {
    switch (action.type) {
        case FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_ACTION: {
            return {
                ...state,
                isLoadingEmployeeDepartmentEngagement: true,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_SUCCESS_ACTION: {
            return {
                ...state,
                employeeDepartmentEngagementData:
                    action.employeeDepartmentEngagementData,
                isLoadingEmployeeDepartmentEngagement: false,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_DEPARTMENT_ENGAGEMENT_FAILURE_ACTION: {
            return {
                ...state,
                employeeDepartmentEngagementData: {},
                isLoadingEmployeeDepartmentEngagement: false,
                error: action.error,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
