import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { styles } from './styles';

const CustomPaper = ({ classes, children, className }) => (
    <Paper className={`${className} ${classes.paper}`}>{children}</Paper>
);

CustomPaper.defaultProps = {
    className: '',
    children: '',
};
CustomPaper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomPaper);
