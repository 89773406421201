import {
    clear as storageClear,
    get as storageGet,
    put as storagePut,
} from '../../../utils/storage';
import {
    FETCH_USER_DATA_ACTION,
    AUTH_SUCCESS_ACTION,
    FETCH_USER_FEATURES_ACTION,
    FETCH_USER_FEATURES_SUCCESS_ACTION,
    FETCH_USER_FEATURES_FAILURE_ACTION,
    FETCH_USER_DATA_SUCCESS_ACTION,
    FETCH_USER_DATA_FAILURE_ACTION,
} from './auth.action-types';

const initialState = {
    token: storageGet('token') || null,
    isLightMode: false,
    currentLanguage: '',
    isOKR: false,
    isFeedback: false,
    isLoadingFeatures: false,
    isLoggingIn: false,
    executive: null,
    userType: null,
};

export function auth(state = initialState, action) {
    switch (action.type) {
        case AUTH_SUCCESS_ACTION: {
            storageClear();
            storagePut('token', action.token);
            return {
                ...state,
                token: action.token,
                isLightMode: action.isLightMode,
                currentLanguage: action.currentLanguage,
            };
        }
        case FETCH_USER_DATA_ACTION: {
            return {
                ...state,
                isLoggingIn: true,
                error: null,
            };
        }
        case FETCH_USER_DATA_SUCCESS_ACTION: {
            return {
                ...state,
                isLoggingIn: false,
                executive: action.executive,
                userType: action.userType,
                error: null,
            };
        }
        case FETCH_USER_DATA_FAILURE_ACTION: {
            return {
                ...state,
                isLoggingIn: false,
                executive: null,
                userType: null,
                error: action.error,
            };
        }
        case FETCH_USER_FEATURES_ACTION: {
            return {
                ...state,
                isLoadingFeatures: true,
                error: null,
            };
        }
        case FETCH_USER_FEATURES_SUCCESS_ACTION: {
            return {
                ...state,
                isOKR: action.isOKR,
                isFeedback: action.isFeedback,
                isLoadingFeatures: false,
                error: null,
            };
        }
        case FETCH_USER_FEATURES_FAILURE_ACTION: {
            return {
                ...state,
                isOKR: null,
                isFeedback: null,
                isLoadingFeatures: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}
