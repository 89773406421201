export function styles(theme, isLightMode) {
    // eslint-disable-next-line no-param-reassign
    theme.shadows[1] = isLightMode
        ? theme.palette.menuAndNavBar.light.shadows
        : theme.palette.menuAndNavBar.dark.shadows;
    return {
        root: {
            flexGrow: 1,
        },
        header: {
            height: '60px',
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
        },
        headerToolbar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginTop: '25px',
            paddingRight: '5%',
        },
        closeDrawerButton: {
            backgroundRepeat: 'no-repeat',
            border: 'none',
            cursor: 'pointer',
            overflow: 'hidden',
            outline: 'none',
            margin: '15px 15px 5px 5px',
            marginLeft: 'auto',
            color: isLightMode
                ? theme.palette.common.light.text
                : theme.palette.common.dark.text,
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
            '-webkit-tap-highlight-color': isLightMode
                ? theme.palette.menuAndNavBar.light.buttonClicked
                : theme.palette.menuAndNavBar.dark.buttonClicked,
        },
        navBarButton: {
            backgroundRepeat: 'no-repeat',
            border: 'none',
            cursor: 'pointer',
            overflow: 'hidden',
            outline: 'none',
            color: isLightMode
                ? theme.palette.common.light.text
                : theme.palette.common.dark.text,
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
            '-webkit-tap-highlight-color': isLightMode
                ? theme.palette.menuAndNavBar.light.buttonClicked
                : theme.palette.menuAndNavBar.dark.buttonClicked,
        },
        navLink: {
            display: 'flex',
            alignItems: 'center',
            'text-decoration': 'none',
            'border-radius': '5px',
            color: isLightMode
                ? theme.palette.common.light.text
                : theme.palette.common.dark.text,
            fontSize: '22px',
            fontWeight: 600,
            paddingBottom: '30px',
            width: '100%',
            justifyContent: 'end',
        },
        navLinkIcon: {
            marginRight: '15px',
            marginLeft: 'auto',
            fill: isLightMode
                ? theme.palette.common.light.text
                : theme.palette.common.dark.text,
        },
        drawerPaper: {
            width: '360px',
            [theme.breakpoints.down(theme.sizes.mobileThreshold)]: {
                width: '100%',
            },
        },
        profile: {
            'margin-left': 'auto',
        },
        sidebar: {
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    };
}
