import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import { OverviewIcon, ProgressIcon, OkrIcon, FeedbackIcon } from '../Icons';
import { styles } from './styles';

const NavBar = React.memo(({ token, isLightMode, getUserData }) => {
    const { t, i18n } = useTranslation('translation');
    const currentLanguage = useSelector((state) => state.auth.currentLanguage);
    useEffect(() => {
        if (currentLanguage) i18n.changeLanguage(currentLanguage);
    }, [i18n, currentLanguage]);
    const history = useHistory();
    const [authorized, setAuthorized] = useState(null);
    const [isSidebarOpened, setIsSidebarOpened] = useState(false);
    const executive = useSelector((state) => state.auth.executive);
    const { isOKR, isFeedback } = useSelector((state) => state.auth);
    const classes = makeStyles(() => styles(useTheme(), isLightMode))();
    const location = useLocation();

    useEffect(() => {
        if (token) {
            if (!authorized) {
                getUserData()
                    .then(() => setAuthorized(true))
                    .catch(() => setAuthorized(false));
            }
        } else {
            setAuthorized(false);
        }
    }, [token, authorized, getUserData]);

    return (
        <div className={classes.root}>
            <AppBar elevation={1} className={classes.header}>
                <Toolbar className={classes.headerToolbar}>
                    {location.pathname !== '/dashboard' ? (
                        <button
                            type="button"
                            className={classes.navBarButton}
                            onClick={() => history.goBack()}
                        >
                            <ArrowBackIcon />
                        </button>
                    ) : (
                        <div />
                    )}
                    <button
                        type="button"
                        className={classes.navBarButton}
                        onClick={() => setIsSidebarOpened(true)}
                    >
                        <MoreVertIcon />
                    </button>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="right"
                BackdropProps={{ invisible: true }}
                open={isSidebarOpened}
                onClose={() => setIsSidebarOpened(false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.sidebar}>
                    <button
                        type="button"
                        className={classes.closeDrawerButton}
                        onClick={() => setIsSidebarOpened(false)}
                    >
                        <CloseIcon />
                    </button>
                    <List className={classes.list}>
                        <ListItem
                            onClick={() => setIsSidebarOpened(false)}
                            button
                        >
                            <NavLink
                                exact
                                className={classes.navLink}
                                to="/dashboard"
                            >
                                <OverviewIcon
                                    className={classes.navLinkIcon}
                                    size={36}
                                />
                                {t('overview')}
                            </NavLink>
                        </ListItem>
                        <ListItem
                            onClick={() => setIsSidebarOpened(false)}
                            button
                        >
                            <NavLink
                                exact
                                className={classes.navLink}
                                to="/department-progress"
                            >
                                <ProgressIcon
                                    className={classes.navLinkIcon}
                                    size={36}
                                />
                                {t('departmentProgress')}
                            </NavLink>
                        </ListItem>
                        {!!isOKR && executive && (
                            <ListItem
                                onClick={() => setIsSidebarOpened(false)}
                                button
                            >
                                <NavLink
                                    exact
                                    className={classes.navLink}
                                    to="/okrs"
                                >
                                    <OkrIcon
                                        className={classes.navLinkIcon}
                                        size={36}
                                    />
                                    {t('okrs')}
                                </NavLink>
                            </ListItem>
                        )}
                        {!!isFeedback && executive && (
                            <ListItem
                                onClick={() => setIsSidebarOpened(false)}
                                button
                            >
                                <NavLink
                                    exact
                                    className={classes.navLink}
                                    to="/feedbacks"
                                >
                                    <FeedbackIcon
                                        className={classes.navLinkIcon}
                                        size={36}
                                    />
                                    {t('feedback')}
                                </NavLink>
                            </ListItem>
                        )}
                    </List>
                </div>
            </Drawer>
        </div>
    );
});

NavBar.propTypes = {
    token: PropTypes.string,
    isLightMode: PropTypes.bool,
    getUserData: PropTypes.func.isRequired,
};

NavBar.defaultProps = {
    token: '',
    isLightMode: false,
};

export { NavBar };
