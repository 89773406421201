import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, component: Component, ...rest }) => (
    <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(props) =>
            isAuthenticated ? (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Component {...props} />
            ) : (
                <Redirect to="/dashboard" />
            )
        }
    />
);

ProtectedRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.object.isRequired,
};

export default ProtectedRoute;
