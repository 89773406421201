export const styles = (theme, isLightMode) => ({
    root: {
        padding: '60px 24px 24px 24px',
        display: 'flex',
        justifyContent: 'center',
    },
    innerWrapper: {
        maxWidth: 500,
        flex: 1,
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    titleWrapper: {
        marginTop: 35,
        padding: '0 44px',
    },
    subTitle: {
        marginTop: 4,
        fontSize: 20,
    },
    chartWrapper: {
        marginTop: 33,
        textAlign: 'center',
    },
    chart: {
        width: 138,
        height: 132,
    },
    chartImg: {
        width: 145,
        height: 132,
    },
    keyResults: {
        margin: '37px 41px 0',
    },
});
