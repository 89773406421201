export function styles(theme) {
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        iconsOuterWrapper: {
            display: 'flex',
            justifyContent: 'center',
        },
        iconsInnerWrapper: {
            position: 'relative',
        },
        arrowIconWrapper: {
            position: 'absolute',
            left: -21,
            top: 20,
        },
        percentageWrapper: {
            position: 'absolute',
            right: -19,
            bottom: -10,
        },
        circleIcon: {},
        percentage: {
            color: theme.palette.textColor.white,
            fontSize: '12px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -63%)',
        },
        title: {
            marginTop: 10,
            fontSize: 12,
        },
    };
}
