import indigo from '@material-ui/core/colors/indigo';

export function styles(theme, isLightMode) {
    return {
        root: {
            paddingTop: '60px',
            paddingBottom: '30px',
            display: 'flex',
            flex: 1,
            maxWidth: '100vw',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            position: 'fixed',
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
        },
        playoffSection: {
            padding: '32px 16px 0',
            [theme.breakpoints.up(theme.sizes.mobileThreshold)]: {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            },
        },
        progressChartPaper: {
            display: 'flex',
            justifyContent: 'space-around',
            padding: '20px 0',
            [theme.breakpoints.up(theme.sizes.mobileThreshold)]: {
                width: '49%',
                boxSizing: 'border-box',
            },
            color: isLightMode
                ? theme.palette.common.light.text
                : theme.palette.common.dark.text,
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
            // boxShadow: isLightMode
            //     ? theme.palette.paperComponent.light.boxShadow
            //     : theme.palette.paperComponent.dark.boxShadow,
        },
        progressChartWrapper: {
            display: 'flex',
        },
        progressChartDescription: {
            width: '40%',
        },
        progressChartDescriptionTitle: {
            fontSize: '20px',
            fontWeight: 600,
            marginTop: '3px',
            marginBottom: '12px',
        },
        progressChartDescriptionSubTitle: {
            fontSize: '12px',
        },
        levelChartPaper: {
            marginTop: '16px',
            padding: '20px 26px 30px 26px',
            [theme.breakpoints.up(theme.sizes.mobileThreshold)]: {
                boxSizing: 'border-box',
                width: '49%',
                marginTop: 0,
            },
            color: isLightMode
                ? theme.palette.common.light.text
                : theme.palette.common.dark.text,
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
            // boxShadow: isLightMode
            //     ? theme.palette.paperComponent.light.boxShadow
            //     : theme.palette.paperComponent.dark.boxShadow,
        },
        levelChartTitle: {
            fontSize: '20px',
            fontWeight: 600,
            marginBottom: '15px',
        },
        badgesPaper: {
            marginTop: '16px',
            padding: '16px 26px 25px',
            [theme.breakpoints.up(theme.sizes.mobileThreshold)]: {
                width: '49%',
                boxSizing: 'border-box',
            },
            color: isLightMode
                ? theme.palette.common.light.text
                : theme.palette.common.dark.text,
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
            // boxShadow: isLightMode
            //     ? theme.palette.paperComponent.light.boxShadow
            //     : theme.palette.paperComponent.dark.boxShadow,
        },
        badgesTitle: {
            fontSize: '20px',
            fontWeight: 600,
            marginBottom: '15px',
        },
        badgeList: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
        },
        scoresWrapper: {
            marginTop: '14px',
            padding: '16px 26px',
            [theme.breakpoints.up(theme.sizes.mobileThreshold)]: {
                width: '49%',
                boxSizing: 'border-box',
            },
            color: isLightMode
                ? theme.palette.common.light.text
                : theme.palette.common.dark.text,
            backgroundColor: isLightMode
                ? theme.palette.common.light.background
                : theme.palette.common.dark.background,
            // boxShadow: isLightMode
            //     ? theme.palette.paperComponent.light.boxShadow
            //     : theme.palette.paperComponent.dark.boxShadow,
        },
        scoresTitle: {
            fontSize: '20px',
            fontWeight: 600,
            marginBottom: '18px',
        },
        scoresMetricWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: '9px 0',
        },
        scoresMetricLearn: {
            fontSize: '16px',
            margin: '4px 0',
        },
        scoresMetricEngagement: {
            fontSize: '16px',
            margin: '4px 0',
        },
        tabsBar: {
            display: 'inline-flex',
        },
        tabItem: {
            fontSize: '1rem',
            color: theme.palette.text.primary,
        },
        selectedTab: {
            'background-color': indigo[800],
        },
        pageWarningLabel: {
            boxSizing: 'border-box',
            margin: '32px 16px 0',
        },
    };
}
