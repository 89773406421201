import PropTypes from 'prop-types';
import React from 'react';

const StarIconSmFill = ({ className, width }) => (
    <svg
        className={className}
        width={width}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.9686 6.82515C18.8944 6.59928 18.6964 6.43234 18.4588 6.39797L12.6576 5.56323L10.0688 0.348623C9.96486 0.132575 9.74212 0 9.50453 0C9.26694 0 9.04419 0.137485 8.94025 0.348623L6.34653 5.56323L0.540367 6.40288C0.302775 6.43725 0.104781 6.60419 0.0305334 6.83006C-0.0437142 7.05593 0.0206337 7.30635 0.188928 7.47329L4.38639 11.534L3.39643 17.2691C3.35683 17.5048 3.45087 17.7405 3.64887 17.878C3.84191 18.0204 4.10425 18.04 4.31215 17.9271L9.49958 15.2216L14.687 17.9271C14.7761 17.9762 14.8801 17.9958 14.9791 17.9958C15.1077 17.9958 15.2414 17.9565 15.3503 17.8731C15.5433 17.7307 15.6423 17.4999 15.6027 17.2642L14.6128 11.5291L18.8102 7.47329C18.9835 7.30144 19.0429 7.05102 18.9686 6.82515Z"
            fill="#0696BE"
        />
    </svg>
);

StarIconSmFill.defaultProps = {
    className: '',
    width: 20,
};
StarIconSmFill.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
};

export default StarIconSmFill;
