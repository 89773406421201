import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '../../../../components/Icons/ArrowRightIcon';
import Paper from '../../../../components/Paper';
import PieChart from '../../../../components/PieChart/PieChart';
import { styles } from './styles';

const ObjectiveCard = ({ className, title, progress, order }) => {
    const { t } = useTranslation('translation');
    const datasets = [
        {
            backgroundColor: ['#B82C54', '#56CCF2'],
            data: [progress, 100 - progress],
        },
    ];
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();
    return (
        <Link
            to={`/okrs/${encodeURI(title)}`}
            className={`${classes.link} ${className}`}
        >
            <Paper className={classes.root}>
                <PieChart
                    datasets={datasets}
                    className={classes.chart}
                    imgClassName={classes.chartImg}
                />
                <div className={classes.titleWrapper}>
                    <div className={classes.title}>
                        {t('objective')} #{order}
                    </div>
                    <div className={classes.subTitle}>{title}</div>
                </div>
                <ArrowRightIcon />
            </Paper>
        </Link>
    );
};

ObjectiveCard.defaultProps = {
    className: '',
    progress: 0,
};
ObjectiveCard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    progress: PropTypes.number,
    order: PropTypes.number.isRequired,
};

export default ObjectiveCard;
