import PropTypes from 'prop-types';
import React from 'react';

const RefreshIcon = ({ className, size, children }) => (
    <svg
        className={className}
        width={size}
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <text x="15" y="28" fill="#0A96BE">
            {children}
        </text>
        <path
            d="M24.4001 0.150028C22.9501 2.83197e-05 21.5001 2.83197e-05 20.0501 0.100028C18.9501 0.200028 18.1501 1.15003 18.2501 2.25003C18.2501 2.50003 18.3501 2.75003 18.4501 3.00003C18.8001 3.75003 19.6001 4.15003 20.4001 4.10003C21.2501 4.05003 22.0501 4.00003 22.9001 4.05003C32.8501 4.55003 40.5001 13 40.0001 22.9C39.5001 32.8 31.0501 40.5 21.1001 40C11.2001 39.5 3.5501 31.05 4.0501 21.1C4.2001 18.15 5.0501 15.3 6.6001 12.75C6.7001 12.6 6.8001 12.5 7.0001 12.5C7.1501 12.5 7.3001 12.55 7.4001 12.65L9.8001 15.4L12.2001 4.55003L1.1501 5.55003L3.9501 8.75003C4.1001 8.90003 4.1001 9.20003 4.0001 9.35003C-2.9999 19.35 -0.549904 33.05 9.4001 40.05C17.9001 46 29.3501 45.2 36.9501 38.2C41.4001 34.15 43.9501 28.45 44.0501 22.45C44.2001 11.05 35.7001 1.40003 24.4001 0.150028Z"
            fill="#0A96BE"
        />
    </svg>
);

RefreshIcon.defaultProps = {
    className: '',
    size: 38,
    children: '',
};
RefreshIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    children: PropTypes.string,
};

export default RefreshIcon;
