import { Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import Paper from '../../../../components/Paper';
import { styles } from './styles';

const KeyResult = ({ title, progress, order, enabled, rateLink }) => {
    const { t } = useTranslation('translation');
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();

    return (
        <Paper className={classes.root}>
            <div className={classes.main}>
                <div className={classes.titleWrapper}>
                    <div className={classes.title}>
                        {t('keyResult')} #{order}
                    </div>
                    <div className={classes.subTitle}>{title}</div>
                </div>
                <div className={classes.progress}>{progress}%</div>
            </div>
            {enabled && (
                <Link href={rateLink} className={classes.rateLink}>
                    <Button classes={{ root: classes.rateButton }}>
                        {t('rateItNow')}
                    </Button>
                </Link>
            )}
        </Paper>
    );
};

KeyResult.propTypes = {
    order: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    enabled: PropTypes.bool.isRequired,
    rateLink: PropTypes.string.isRequired,
};

export default KeyResult;
