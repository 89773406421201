import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
    common: {
        dark: {
            background: '#09151F',
            text: '#FAFDFF',
        },
        light: {
            background: '#FAFDFF',
            text: '#0E1A24',
        },
    },
    menuAndNavBar: {
        dark: {
            buttonClicked: '#1C262E',
            shadows:
                '0px 2px 1px -1px #28343E,0px 1px 1px 0px #28343E,0px 1px 3px 0px #28343E',
        },
        light: {
            buttonClicked: '#EFF4F6',
            shadows:
                '0px 2px 1px -1px #E0E7EB,0px 1px 1px 0px #E0E7EB,0px 1px 3px 0px #E0E7EB',
        },
    },
    paperComponent: {
        dark: {
            boxShadow: '8px 10px 18px 10px rgba(255, 255, 255, 0.05)',
        },
        light: {
            boxShadow: '2px 4px 12px 4px rgba(0, 0, 0, 0.05)',
        },
    },
    background: {
        default: '#fff',
    },
    textColor: {
        white: '#fff',
        primary: '#3f51b5',
        success: '#33a853',
        grey: '#828282',
        black: '#000000',
    },
    yellow: '#F2C94C',
    red: '#B82C54',
    blue: '#0A96BE',
    blueTransparent: 'rgba(10, 150, 190, 0.15)',
    redTransparent: 'rgba(184, 44, 84, 0.15)',
};

const sizes = {
    mobileThreshold: 780,
};

const typography = {
    fontFamily: `"Poppins", sans-serif`,
};

export const theme = createMuiTheme({ palette, sizes, typography });
