import React from 'react';
import { useSelector } from 'react-redux';
import PieChart from '../../../../components/PieChart/PieChart';

function getChartData(progress) {
    const total = 100;

    return {
        datasets: [
            {
                backgroundColor: ['#B82C54', '#56CCF2'],
                data: [progress, total - progress],
            },
        ],
        text: `${progress}%`,
    };
}

export default React.memo(() => {
    const progress = useSelector((state) => state.dashboard.progress);
    const { datasets, text } = getChartData(progress);

    return <PieChart datasets={datasets} text={text} />;
});
