import {
    FETCH_USER_DATA_ACTION,
    FETCH_USER_DATA_SUCCESS_ACTION,
    FETCH_USER_DATA_FAILURE_ACTION,
    AUTH_SUCCESS_ACTION,
    FETCH_USER_FEATURES_ACTION,
    FETCH_USER_FEATURES_SUCCESS_ACTION,
    FETCH_USER_FEATURES_FAILURE_ACTION,
} from './auth.action-types';

export const authSuccessAction = (token, isLightMode, currentLanguage) => ({
    type: AUTH_SUCCESS_ACTION,
    token,
    isLightMode,
    currentLanguage,
});

export const fetchUserDataAction = () => ({
    type: FETCH_USER_DATA_ACTION,
});

export const fetchUserDataSuccessAction = (executive, type) => ({
    type: FETCH_USER_DATA_SUCCESS_ACTION,
    executive,
    userType: type,
});

export const fetchUserDataFailureAction = (error) => ({
    type: FETCH_USER_DATA_FAILURE_ACTION,
    error,
});

export const fetchUserFeaturesAction = () => ({
    type: FETCH_USER_FEATURES_ACTION,
});

export const fetchUserFeaturesSuccessAction = (OKR_TOOL, FEEDBACK_TOOL) => ({
    type: FETCH_USER_FEATURES_SUCCESS_ACTION,
    isOKR: OKR_TOOL,
    isFeedback: FEEDBACK_TOOL,
});

export const fetchUserFeaturesFailureAction = (error) => ({
    type: FETCH_USER_FEATURES_FAILURE_ACTION,
    error,
});
