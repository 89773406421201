import PropTypes from 'prop-types';
import React from 'react';

const ContentIcon = ({ className, size }) => (
    <svg
        className={className}
        width={size}
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M31.4948 31.2004C29.0025 31.2004 26.5101 31.2004 24.0544 31.2004C20.1326 31.2004 16.1741 31.2004 12.2523 31.2004C11.336 31.2004 10.4197 31.2004 9.54 31.2004C9.72326 31.3837 9.90652 31.5669 10.0898 31.7502C10.0898 29.2578 10.0898 26.7655 10.0898 24.3097C10.0898 20.3879 10.0898 16.4295 10.0898 12.5076C10.0898 11.5913 10.0898 10.675 10.0898 9.79535C9.90652 9.97861 9.72326 10.1619 9.54 10.3451C12.0324 10.3451 14.5247 10.3451 16.9805 10.3451C20.9023 10.3451 24.8607 10.3451 28.7826 10.3451C29.6989 10.3451 30.6152 10.3451 31.4948 10.3451C31.3116 10.1619 31.1283 9.97861 30.9451 9.79535C30.9451 12.2877 30.9451 14.7801 30.9451 17.2358C30.9451 21.1576 30.9451 25.1161 30.9451 29.0379C30.9451 29.9542 30.9451 30.8705 30.9451 31.7502C30.9451 32.4466 32.0446 32.4466 32.0446 31.7502C32.0446 29.2578 32.0446 26.7655 32.0446 24.3097C32.0446 20.3879 32.0446 16.4295 32.0446 12.5076C32.0446 11.5913 32.0446 10.675 32.0446 9.79535C32.0446 9.50213 31.7881 9.24556 31.4948 9.24556C29.0025 9.24556 26.5101 9.24556 24.0544 9.24556C20.1326 9.24556 16.1741 9.24556 12.2523 9.24556C11.336 9.24556 10.4197 9.24556 9.54 9.24556C9.24678 9.24556 8.99021 9.50213 8.99021 9.79535C8.99021 12.2877 8.99021 14.7801 8.99021 17.2358C8.99021 21.1576 8.99021 25.1161 8.99021 29.0379C8.99021 29.9542 8.99021 30.8705 8.99021 31.7502C8.99021 32.0434 9.24678 32.3 9.54 32.3C12.0324 32.3 14.5247 32.3 16.9805 32.3C20.9023 32.3 24.8607 32.3 28.7826 32.3C29.6989 32.3 30.6152 32.3 31.4948 32.3C32.1912 32.3 32.1912 31.2004 31.4948 31.2004Z"
            fill="white"
            stroke="white"
            strokeMiterlimit="10"
        />
        <path
            d="M7.89071 26.8388C7.01105 26.8388 6.09474 26.8388 5.21508 26.8388C5.39834 27.0221 5.5816 27.2053 5.76487 27.3886C5.76487 24.8962 5.76487 22.4038 5.76487 19.9481C5.76487 16.0263 5.76487 12.0678 5.76487 8.14602C5.76487 7.22971 5.76487 6.31339 5.76487 5.43373C5.5816 5.617 5.39834 5.80026 5.21508 5.98352C7.70745 5.98352 10.1998 5.98352 12.6555 5.98352C16.5774 5.98352 20.5358 5.98352 24.4576 5.98352C25.374 5.98352 26.2903 5.98352 27.1699 5.98352C26.9867 5.80026 26.8034 5.617 26.6201 5.43373C26.6201 6.46 26.6201 7.52293 26.6201 8.5492C26.6201 9.24559 27.7197 9.24559 27.7197 8.5492C27.7197 7.52293 27.7197 6.46 27.7197 5.43373C27.7197 5.14051 27.4631 4.88395 27.1699 4.88395C24.6776 4.88395 22.1852 4.88395 19.7295 4.88395C15.8077 4.88395 11.8492 4.88395 7.92736 4.88395C7.01105 4.88395 6.09474 4.88395 5.21508 4.88395C4.92186 4.88395 4.66529 5.14051 4.66529 5.43373C4.66529 7.9261 4.66529 10.4185 4.66529 12.8742C4.66529 16.796 4.66529 20.7545 4.66529 24.6763C4.66529 25.5926 4.66529 26.5089 4.66529 27.3886C4.66529 27.6818 4.92186 27.9384 5.21508 27.9384C6.09474 27.9384 7.01105 27.9384 7.89071 27.9384C8.58711 27.9384 8.58711 26.8388 7.89071 26.8388Z"
            fill="white"
            stroke="white"
            strokeMiterlimit="10"
        />
        <path
            d="M3.78559 23.0269C3.05254 23.0269 2.28284 23.0269 1.54979 23.0269C1.73305 23.2102 1.91631 23.3934 2.09958 23.5767C2.09958 21.0843 2.09958 18.5919 2.09958 16.1362C2.09958 12.2144 2.09958 8.25594 2.09958 4.33413C2.09958 3.41781 2.09958 2.5015 2.09958 1.62184C1.91631 1.76845 1.73305 1.95171 1.54979 2.13498C4.04216 2.13498 6.53453 2.13498 8.99025 2.13498C12.9121 2.13498 16.8705 2.13498 20.7924 2.13498C21.7087 2.13498 22.625 2.13498 23.5046 2.13498C23.3214 1.95171 23.1381 1.76845 22.9548 1.58519C22.9548 2.39154 22.9548 3.23455 22.9548 4.04091C22.9548 4.7373 24.0544 4.7373 24.0544 4.04091C24.0544 3.23455 24.0544 2.39154 24.0544 1.58519C24.0544 1.29197 23.7979 1.0354 23.5046 1.0354C21.0123 1.0354 18.5199 1.0354 16.0642 1.0354C12.1424 1.0354 8.18389 1.0354 4.26207 1.0354C3.34576 1.0354 2.42945 1.0354 1.54979 1.0354C1.25657 1.0354 1 1.29197 1 1.58519C1 4.07756 1 6.56993 1 9.02565C1 12.9475 1 16.9059 1 20.8278C1 21.7441 1 22.6604 1 23.54C1 23.8333 1.25657 24.0898 1.54979 24.0898C2.28284 24.0898 3.05254 24.0898 3.78559 24.0898C4.51864 24.1265 4.51864 23.0269 3.78559 23.0269Z"
            fill="white"
            stroke="white"
            strokeMiterlimit="10"
        />
    </svg>
);

ContentIcon.defaultProps = {
    className: '',
    size: 33,
};
ContentIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
};

export default ContentIcon;
