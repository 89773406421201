import PropTypes from 'prop-types';
import React from 'react';

const FeedbackIcon = ({ className, size }) => (
    <svg
        className={className}
        width={size}
        viewBox="0 0 120.02 100.6"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M2,100H2a3.23,3.23,0,0,0,1.58.4A3.3,3.3,0,0,0,5.4,99.8h0l19.93-13h82.32a12.13,12.13,0,0,0,12.11-12.12V12.33h0A12.11,12.11,0,0,0,107.66.25H12.36A12.12,12.12,0,0,0,.25,12.36V97A3.36,3.36,0,0,0,2,100ZM22.55,80.69h0L7,90.84V12.33a5.42,5.42,0,0,1,5.42-5.41h95.27a5.42,5.42,0,0,1,5.42,5.41V74.72a5.42,5.42,0,0,1-5.42,5.42H24.37A3.35,3.35,0,0,0,22.55,80.69Z" />
        <path d="M51.37,32.36,36.1,47.63l-5.48-5.49a3.34,3.34,0,1,0-4.72,4.72l7.86,7.86a3.37,3.37,0,0,0,2.37,1,3.31,3.31,0,0,0,2.38-1L56.12,37.11a3.36,3.36,0,0,0-4.75-4.75Z" />
        <path d="M71.76,37.08l6.46,6.46L71.76,50a3.34,3.34,0,0,0,0,4.72,3.41,3.41,0,0,0,2.38,1,3.28,3.28,0,0,0,2.37-1L83,48.26l6.46,6.46a3.39,3.39,0,0,0,2.37,1A3.35,3.35,0,0,0,94.18,50l-6.46-6.46,6.46-6.46h0l0,0a3.36,3.36,0,0,0-4.8-4.7l-6.46,6.46-6.45-6.46a3.34,3.34,0,0,0-4.73,4.72Z" />
    </svg>
);

FeedbackIcon.defaultProps = {
    className: '',
    size: 36,
};
FeedbackIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
};

export default FeedbackIcon;
