export function styles(theme) {
    return {
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        level: {
            fontSize: '18px',
            fontWeight: 600,
        },
        progressBarWrapper: {
            height: '39px',
            margin: '0 6px',
            borderRadius: '4px',
            backgroundColor: theme.palette.yellow,
            flex: 1,
            overflow: 'hidden',
        },
        progressBar: {
            backgroundColor: theme.palette.red,
            height: '100%',
        },
    };
}
