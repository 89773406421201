export const styles = (theme, isLightMode) => ({
    mainRoot: {
        padding: '0 24px',
        [theme.breakpoints.up(theme.sizes.mobileThreshold)]: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
    },
    typeRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 10px',
        marginTop: '8px',
        marginLeft: '8px',
        marginBottom: '8px',
        height: '60px',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: '100px 20px 40px 0',
        padding: '0px 35px',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
    },
    link: {
        textDecoration: 'none',
    },
    sessionData: {
        fontSize: '12px',
    },
    status: {
        color: 'yellow',
    },
    type: {
        fontWeight: 600,
    },
    submitButton: {
        marginTop: '20px',
        marginBottom: '20px',
        width: '80%',
    },
    snackbar: {
        position: 'absolute',
        marginTop: '290px',
    },
});
