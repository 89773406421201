import PropTypes from 'prop-types';
import React from 'react';

const OkrIcon = ({ className, size }) => (
    <svg
        className={className}
        width={size}
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.2276 6.03283C25.3415 6.03283 31.9457 12.6233 31.9457 20.7201C31.9457 28.817 25.3415 35.4074 17.2276 35.4074C9.11382 35.4074 2.50955 28.817 2.50955 20.7201C2.50955 12.6233 9.11382 6.03283 17.2276 6.03283ZM17.2276 4.52644C8.28357 4.52644 1 11.7948 1 20.7201C1 29.6455 8.28357 36.9138 17.2276 36.9138C26.1717 36.9138 33.4553 29.6455 33.4553 20.7201C33.4553 11.7948 26.1717 4.52644 17.2276 4.52644Z"
            strokeWidth="0.25"
            strokeMiterlimit="10"
        />
        <path
            d="M17.2277 10.9286C22.6243 10.9286 27.0397 15.3348 27.0397 20.7201C27.0397 26.1055 22.6243 30.5117 17.2277 30.5117C11.831 30.5117 7.4156 26.1055 7.4156 20.7201C7.4156 15.3348 11.831 10.9286 17.2277 10.9286ZM17.2277 9.4222C10.963 9.4222 5.90605 14.4686 5.90605 20.7201C5.90605 26.9716 10.963 32.018 17.2277 32.018C23.4923 32.018 28.5493 26.9716 28.5493 20.7201C28.5493 14.4686 23.4923 9.4222 17.2277 9.4222Z"
            strokeWidth="0.25"
            strokeMiterlimit="10"
        />
        <path
            d="M17.2276 16.2009C19.7184 16.2009 21.7563 18.2345 21.7563 20.7201C21.7563 23.2056 19.7184 25.2393 17.2276 25.2393C14.7369 25.2393 12.699 23.2056 12.699 20.7201C12.699 18.2345 14.7369 16.2009 17.2276 16.2009ZM17.2276 14.6945C13.9066 14.6945 11.1894 17.406 11.1894 20.7201C11.1894 24.0341 13.9066 26.7456 17.2276 26.7456C20.5486 26.7456 23.2658 24.0341 23.2658 20.7201C23.2658 17.406 20.5486 14.6945 17.2276 14.6945Z"
            strokeWidth="0.25"
            strokeMiterlimit="10"
        />
        <path
            d="M17.2276 21.398C17.0389 21.398 16.8502 21.3227 16.6992 21.172C16.3973 20.8708 16.3973 20.4188 16.6992 20.1176L29.4549 7.38856C29.7568 7.08728 30.2097 7.08728 30.5116 7.38856C30.8135 7.68983 30.8135 8.14175 30.5116 8.44303L17.7559 21.172C17.605 21.3227 17.4163 21.398 17.2276 21.398Z"
            strokeWidth="0.25"
            strokeMiterlimit="10"
        />
        <path
            d="M33.5685 8.55598H30.0965C29.6814 8.55598 29.3417 8.21705 29.3417 7.80279V4.33809C29.3417 4.14979 29.4172 3.96149 29.5682 3.81085L32.1721 1.21233C32.3986 0.986373 32.7005 0.948713 33.0024 1.06169C33.3043 1.17467 33.4552 1.43829 33.4552 1.73957V4.45107H36.1724C36.4743 4.45107 36.7385 4.63937 36.8517 4.90299C36.9649 5.16661 36.8895 5.50554 36.7008 5.7315L34.0968 8.33003C33.9836 8.48066 33.7949 8.55598 33.5685 8.55598ZM30.8513 7.04959H33.2665L34.361 5.95746H32.7005C32.2853 5.95746 31.9457 5.61852 31.9457 5.20427V3.5849L30.8513 4.67703V7.04959Z"
            strokeWidth="0.25"
            strokeMiterlimit="10"
        />
        <path
            d="M17.1899 22.2265C18.0236 22.2265 18.6994 21.552 18.6994 20.7201C18.6994 19.8881 18.0236 19.2137 17.1899 19.2137C16.3562 19.2137 15.6804 19.8881 15.6804 20.7201C15.6804 21.552 16.3562 22.2265 17.1899 22.2265Z"
            strokeWidth="0.25"
            strokeMiterlimit="10"
        />
    </svg>
);

OkrIcon.defaultProps = {
    className: '',
    size: 38,
};
OkrIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
};

export default OkrIcon;
