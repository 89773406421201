import PropTypes from 'prop-types';
import React from 'react';

const ClockIcon = ({ className }) => (
    <svg
        className={className}
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M30.9231 0C13.8447 0 0 13.8448 0 30.9231C0 48.0014 13.8447 61.8461 30.9231 61.8461C48.0014 61.8461 61.8462 48.0014 61.8462 30.9231C61.8462 13.8448 48.0014 0 30.9231 0ZM6.72175 32.2672H4.03279V29.5783H6.72175V32.2672ZM29.5779 4.03312H32.2669V6.72208H29.5779V4.03312ZM12.8599 50.8878L10.9587 48.9866L12.8599 47.0854L14.7611 48.9866L12.8599 50.8878ZM12.8599 14.7614L10.9587 12.8602L12.8599 10.959L14.7611 12.8602L12.8599 14.7614ZM32.2669 57.8124H29.5779V55.1234H32.2669V57.8124ZM40.7277 42.6292L29.5779 31.4794V9.41104H32.2669V30.3661L42.6289 40.728L40.7277 42.6292ZM48.9863 50.8878L47.0851 48.9866L48.9863 47.0854L50.8874 48.9866L48.9863 50.8878ZM48.9863 14.7614L47.0851 12.8602L48.9863 10.959L50.8874 12.8602L48.9863 14.7614ZM55.1231 32.2672V29.5783H57.8121V32.2672H55.1231Z"
            fill="#0A96BE"
        />
    </svg>
);

ClockIcon.defaultProps = {
    className: '',
};
ClockIcon.propTypes = {
    className: PropTypes.string,
};

export default ClockIcon;
