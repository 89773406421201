import {
    FETCH_EMPLOYEE_FEEDBACKS_ACTION,
    FETCH_EMPLOYEE_FEEDBACKS_SUCCESS_ACTION,
    FETCH_EMPLOYEE_FEEDBACKS_FAILURE_ACTION,
    CREATE_EMPLOYEE_FEEDBACK_ACTION,
    CREATE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION,
    CREATE_EMPLOYEE_FEEDBACK_FAILURE_ACTION,
    DELETE_EMPLOYEE_FEEDBACK_ACTION,
    DELETE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION,
    DELETE_EMPLOYEE_FEEDBACK_FAILURE_ACTION,
    CREATE_FEEDBACK_SESSION_ACTION,
    CREATE_FEEDBACK_SESSION_SUCCESS_ACTION,
    CREATE_FEEDBACK_SESSION_FAILURE_ACTION,
    CREATE_OR_UPDATE_SESSION_DATA_ACTION,
    CREATE_OR_UPDATE_SESSION_DATA_SUCCESS_ACTION,
    CREATE_OR_UPDATE_SESSION_DATA_FAILURE_ACTION,
    SEND_SESSION_DATA_BY_EMAIL_ACTION,
    SEND_SESSION_DATA_BY_EMAIL_SUCCESS_ACTION,
    SEND_SESSION_DATA_BY_EMAIL_FAILURE_ACTION,
} from './employeeFeedbacks.action-types';

export const fetchEmployeeFeedbacksAction = () => ({
    type: FETCH_EMPLOYEE_FEEDBACKS_ACTION,
});

export const fetchEmployeeFeedbacksSuccessAction = (employeeFeedbacksList) => ({
    type: FETCH_EMPLOYEE_FEEDBACKS_SUCCESS_ACTION,
    employeeFeedbacksList,
});

export const fetchEmployeeFeedbacksFailureAction = (error) => ({
    type: FETCH_EMPLOYEE_FEEDBACKS_FAILURE_ACTION,
    error,
});

export const createEmployeeFeedbackAction = () => ({
    type: CREATE_EMPLOYEE_FEEDBACK_ACTION,
});

export const createEmployeeFeedbackSuccessAction = (employeeFeedback) => ({
    type: CREATE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION,
    employeeFeedback,
});

export const createEmployeeFeedbackFailureAction = (error) => ({
    type: CREATE_EMPLOYEE_FEEDBACK_FAILURE_ACTION,
    error,
});

export const deleteEmployeeFeedbackAction = () => ({
    type: DELETE_EMPLOYEE_FEEDBACK_ACTION,
});

export const deleteEmployeeFeedbackSuccessAction = (id) => ({
    type: DELETE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION,
    id,
});

export const deleteEmployeeFeedbackFailureAction = (error) => ({
    type: DELETE_EMPLOYEE_FEEDBACK_FAILURE_ACTION,
    error,
});

export const createFeedbackSessionAction = () => ({
    type: CREATE_FEEDBACK_SESSION_ACTION,
});

export const createFeedbackSessionSuccessAction = (feedbackSession) => ({
    type: CREATE_FEEDBACK_SESSION_SUCCESS_ACTION,
    feedbackSession,
});

export const createFeedbackSessionFailureAction = (error) => ({
    type: CREATE_FEEDBACK_SESSION_FAILURE_ACTION,
    error,
});

export const createOrUpdateSessionDataAction = () => ({
    type: CREATE_OR_UPDATE_SESSION_DATA_ACTION,
});

export const createOrUpdateSessionDataSuccessAction = (sessionData) => ({
    type: CREATE_OR_UPDATE_SESSION_DATA_SUCCESS_ACTION,
    sessionData,
});

export const createOrUpdateSessionDataFailureAction = (error) => ({
    type: CREATE_OR_UPDATE_SESSION_DATA_FAILURE_ACTION,
    error,
});

export const sendSessionDataByEmailAction = () => ({
    type: SEND_SESSION_DATA_BY_EMAIL_ACTION,
});

export const sendSessionDataByEmailSuccessAction = (sessionData) => ({
    type: SEND_SESSION_DATA_BY_EMAIL_SUCCESS_ACTION,
    sessionData,
});

export const sendSessionDataByEmailFailureAction = (error) => ({
    type: SEND_SESSION_DATA_BY_EMAIL_FAILURE_ACTION,
    error,
});
