export const FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_ACTION =
    'FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_ACTION';
export const FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_SUCCESS_ACTION =
    'FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_SUCCESS_ACTION';
export const FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_FAILURE_ACTION =
    'FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_FAILURE_ACTION';

export const FETCH_BEST_DEPARTMENT_ACTION = 'FETCH_BEST_DEPARTMENT_ACTION';
export const FETCH_BEST_DEPARTMENT_SUCCESS_ACTION =
    'FETCH_BEST_DEPARTMENT_SUCCESS_ACTION';
export const FETCH_BEST_DEPARTMENT_FAILURE_ACTION =
    'FETCH_BEST_DEPARTMENT_FAILURE_ACTION';

export const FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_ACTION =
    'FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_ACTION';
export const FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_SUCCESS_ACTION =
    'FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_SUCCESS_ACTION';
export const FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_FAILURE_ACTION =
    'FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_FAILURE_ACTION';
