import moment from 'moment';
import {
    CREATE_EMPLOYEE_FEEDBACK_ACTION,
    CREATE_EMPLOYEE_FEEDBACK_FAILURE_ACTION,
    CREATE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION,
    CREATE_FEEDBACK_SESSION_ACTION,
    CREATE_FEEDBACK_SESSION_FAILURE_ACTION,
    CREATE_FEEDBACK_SESSION_SUCCESS_ACTION,
    CREATE_OR_UPDATE_SESSION_DATA_ACTION,
    CREATE_OR_UPDATE_SESSION_DATA_FAILURE_ACTION,
    CREATE_OR_UPDATE_SESSION_DATA_SUCCESS_ACTION,
    DELETE_EMPLOYEE_FEEDBACK_ACTION,
    DELETE_EMPLOYEE_FEEDBACK_FAILURE_ACTION,
    DELETE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION,
    FETCH_EMPLOYEE_FEEDBACKS_ACTION,
    FETCH_EMPLOYEE_FEEDBACKS_FAILURE_ACTION,
    FETCH_EMPLOYEE_FEEDBACKS_SUCCESS_ACTION,
    SEND_SESSION_DATA_BY_EMAIL_ACTION,
    SEND_SESSION_DATA_BY_EMAIL_SUCCESS_ACTION,
    SEND_SESSION_DATA_BY_EMAIL_FAILURE_ACTION,
} from './employeeFeedbacks.action-types';

const initialState = {
    isLoadingEmployeeFeedbacks: false,
    error: null,
    employeeFeedbacksList: [],
};

const sortFeedbacksByCreatedAt = (a, b) => {
    const aDate = moment(a.createdAt).unix();
    const bDate = moment(b.createdAt).unix();
    if (aDate < bDate) return -1;
    return 0;
};

const sortSessionsByDate = (a, b) => {
    const aDate = moment(a.date).unix();
    const bDate = moment(b.date).unix();
    if (aDate < bDate) return -1;
    return 0;
};

const parseSessionData = (feedback) => {
    const validSessions = feedback.sessions.map((session) => {
        const validSessionData = session.sessionData.map((sessionData) => {
            let { data } = sessionData;
            try {
                data = JSON.parse(data);
            } catch (e) {
                //
            }
            return {
                ...sessionData,
                data,
            };
        });
        return { ...session, sessionData: validSessionData };
    });
    return {
        ...feedback,
        sessions: validSessions,
    };
};

const calculateStatus = (feedback) => {
    const sessionsWithStatuses = feedback.sessions.map((session) => {
        const sessionDataWithStatus = session.sessionData.map((sessionData) => {
            const fields = Object.keys(sessionData.data);
            const numberOfFields = fields.length;
            let filledField = 0;
            fields.forEach((field) => {
                if (sessionData.data[field] !== '') filledField += 1;
            });
            const status = filledField / numberOfFields;
            return { ...sessionData, status };
        });

        const sessionStatus =
            sessionDataWithStatus.reduce((status, data) => {
                return status + data.status;
            }, 0) / 3;
        return {
            ...session,
            sessionData: sessionDataWithStatus,
            status: sessionStatus,
        };
    });
    return { ...feedback, sessions: sessionsWithStatuses };
};

export function employeeFeedbacks(state = initialState, action) {
    switch (action.type) {
        case FETCH_EMPLOYEE_FEEDBACKS_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: true,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_FEEDBACKS_SUCCESS_ACTION: {
            const feedbacksWithStatuses = action.employeeFeedbacksList.map(
                (feedback) => {
                    const readyFeedback = calculateStatus(
                        parseSessionData(feedback),
                    );
                    readyFeedback.sessions.sort(sortSessionsByDate);
                    return readyFeedback;
                },
            );
            return {
                ...state,
                employeeFeedbacksList: feedbacksWithStatuses.sort(
                    sortFeedbacksByCreatedAt,
                ),
                isLoadingEmployeeFeedbacks: false,
                error: null,
            };
        }
        case FETCH_EMPLOYEE_FEEDBACKS_FAILURE_ACTION: {
            return {
                ...state,
                employeeFeedbacksList: [],
                isLoadingEmployeeFeedbacks: false,
                error: action.error,
            };
        }
        case CREATE_EMPLOYEE_FEEDBACK_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: true,
                error: null,
            };
        }
        case CREATE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION: {
            action.employeeFeedback.sessions.sort(sortSessionsByDate);
            return {
                ...state,
                employeeFeedbacksList: [
                    ...state.employeeFeedbacksList,
                    action.employeeFeedback,
                ].sort(sortFeedbacksByCreatedAt),
                error: null,
            };
        }
        case CREATE_EMPLOYEE_FEEDBACK_FAILURE_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: false,
                error: action.error,
            };
        }
        case DELETE_EMPLOYEE_FEEDBACK_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: true,
                error: null,
            };
        }
        case DELETE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION: {
            return {
                ...state,
                employeeFeedbacksList: state.employeeFeedbacksList.filter(
                    (feedback) => feedback.id !== action.id,
                ),
                error: null,
            };
        }
        case DELETE_EMPLOYEE_FEEDBACK_FAILURE_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: false,
                error: action.error,
            };
        }
        case CREATE_FEEDBACK_SESSION_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: true,
                error: null,
            };
        }
        case CREATE_FEEDBACK_SESSION_SUCCESS_ACTION: {
            return {
                ...state,
                employeeFeedbacksList: state.employeeFeedbacksList.map(
                    (feedback) => {
                        if (feedback.id === action.feedbackSession.feedbackId) {
                            const updatedSessions = [
                                ...feedback.sessions,
                                { ...action.feedbackSession, status: 0 },
                            ];
                            updatedSessions.sort(sortSessionsByDate);
                            return { ...feedback, sessions: updatedSessions };
                        }
                        return feedback;
                    },
                ),
                error: null,
            };
        }
        case CREATE_FEEDBACK_SESSION_FAILURE_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: false,
                error: action.error,
            };
        }
        case CREATE_OR_UPDATE_SESSION_DATA_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: true,
                error: null,
            };
        }
        case CREATE_OR_UPDATE_SESSION_DATA_SUCCESS_ACTION: {
            return {
                ...state,
                employeeFeedbacksList: state.employeeFeedbacksList.map(
                    (feedback) => {
                        const newSession = feedback.sessions.map((session) => {
                            if (
                                session.id ===
                                action.sessionData.feedbackSessionId
                            ) {
                                const sessionData = [
                                    ...session.sessionData.filter(
                                        (data) =>
                                            data.sessionType !==
                                            action.sessionData.sessionType,
                                    ),
                                    action.sessionData,
                                ];
                                return {
                                    ...session,
                                    sessionData,
                                };
                            }
                            return { ...session };
                        });
                        const newFeedback = {
                            ...feedback,
                            sessions: newSession,
                        };

                        return calculateStatus(parseSessionData(newFeedback));
                    },
                ),
                error: null,
            };
        }
        case CREATE_OR_UPDATE_SESSION_DATA_FAILURE_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: false,
                error: action.error,
            };
        }
        case SEND_SESSION_DATA_BY_EMAIL_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: true,
                error: null,
            };
        }
        case SEND_SESSION_DATA_BY_EMAIL_SUCCESS_ACTION: {
            return {
                ...state,
                error: null,
            };
        }
        case SEND_SESSION_DATA_BY_EMAIL_FAILURE_ACTION: {
            return {
                ...state,
                isLoadingEmployeeFeedbacks: false,
                error: action.error,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
