import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ScrollView from '../../components/ScrollView';
import Title from '../../components/Title';
import {
    addFeedbackSession,
    fetchEmployeeFeedbacksList,
} from '../../store/modules/employeeFeedbacks/employeeFeedbacks.thunks';
import { FeedbackSessionsList } from './components/FeedbackSessionsList';
import { styles } from './styles';

const Sessions = React.memo(() => {
    const { feedbackId: currentFeedbackId } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchEmployeeFeedbacksList());
    }, [dispatch]);
    const employeeFeedback = useSelector((state) => {
        return state.employeeFeedbacks.employeeFeedbacksList.find(
            (feedback) => currentFeedbackId === feedback.id,
        );
    });
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();
    const data = useMemo(() => {
        if (!employeeFeedback) return {};
        const sessionsLength = employeeFeedback.sessions.length;
        if (employeeFeedback.frequency === 'other') {
            return {
                feedbackId: employeeFeedback.id,
                frequency: employeeFeedback.frequency,
                date: moment(),
            };
        }
        return {
            feedbackId: employeeFeedback.id,
            frequency: employeeFeedback.frequency,
            date: employeeFeedback.sessions[sessionsLength - 1].date,
        };
    }, [employeeFeedback]);

    const createNewSession = useCallback(
        () => dispatch(addFeedbackSession(data)),
        [dispatch, data],
    );

    return (
        <Container className={classes.root} spacing={0}>
            <Container className={classes.title}>
                <Title>{employeeFeedback ? employeeFeedback.name : ''}</Title>
                <button
                    onClick={createNewSession}
                    type="button"
                    className={classes.button}
                >
                    <AddIcon style={{ color: '#B82C54', fontSize: '30px' }} />
                </button>
            </Container>
            <ScrollView>
                {employeeFeedback ? (
                    <FeedbackSessionsList
                        sessions={employeeFeedback.sessions}
                    />
                ) : (
                    <div />
                )}
            </ScrollView>
        </Container>
    );
});

export default Sessions;
