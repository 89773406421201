import PropTypes from 'prop-types';
import React from 'react';

const StarIconSm = ({ className, width }) => (
    <svg
        className={className}
        width={width}
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.4218 6.91016C19.3476 6.68359 19.1523 6.51953 18.9179 6.48437L13.1718 5.64844L10.6015 0.441406C10.4961 0.226563 10.2773 0.09375 10.0429 0.09375C9.80855 0.09375 9.5859 0.230469 9.48433 0.441406L6.91402 5.64844L1.16012 6.48828C0.925741 6.52344 0.730428 6.6875 0.656209 6.91406C0.581991 7.14062 0.644491 7.39062 0.812459 7.55469L4.97262 11.6094L3.99215 17.3359C3.95308 17.5703 4.04683 17.8086 4.24215 17.9453C4.43355 18.0859 4.69137 18.1055 4.8984 17.9922L10.039 15.2891L15.1796 17.9922C15.2695 18.0391 15.3711 18.0625 15.4687 18.0625C15.5976 18.0625 15.7265 18.0234 15.8359 17.9414C16.0273 17.8008 16.125 17.5664 16.0859 17.332L15.1054 11.6055L19.2656 7.55469C19.4336 7.38672 19.4961 7.13672 19.4218 6.91016ZM13.9961 10.9414C13.8476 11.0859 13.7812 11.293 13.8164 11.4961L14.6406 16.2969L10.3281 14.0312C10.1445 13.9336 9.92965 13.9336 9.74605 14.0312L5.43355 16.2969L6.25777 11.4961C6.29293 11.293 6.22652 11.0859 6.07808 10.9414L2.5898 7.54297L7.41012 6.84375C7.61324 6.8125 7.78902 6.6875 7.87887 6.5L10.039 2.13281L12.1953 6.5C12.2851 6.68359 12.4609 6.8125 12.664 6.84375L17.4843 7.54297L13.9961 10.9414Z"
            fill="#0A96BE"
        />
    </svg>
);

StarIconSm.defaultProps = {
    className: '',
    width: 20,
};
StarIconSm.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
};

export default StarIconSm;
