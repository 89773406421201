export const styles = (theme, isLightMode) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    content: {
        width: '100%',
        margin: '0 36px',
        maxWidth: 768,
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
        boxShadow: isLightMode
            ? theme.palette.paperComponent.light.boxShadow
            : theme.palette.paperComponent.dark.boxShadow,
    },
    headerWrapper: {
        position: 'relative',
        padding: '35px 25px',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    closeIcon: {
        position: 'absolute',
    },
    title: {},
    select: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 37px',
        marginBottom: 70,
    },
    option: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 600,
        padding: '10px 0',
        color: theme.palette.blue,
        opacity: 0.63,
        cursor: 'pointer',
    },
    optionSelected: {
        backgroundColor: theme.palette.blue,
        color: theme.palette.textColor.white,
        opacity: 1,
        borderRadius: 4,
    },
});
