export function styles() {
    return {
        chartWrapper: {
            position: 'absolute',
            visibility: 'hidden',
            height: '164px',
            width: '166px',
        },
        chartImg: {
            width: '164px',
            height: '154px',
        },
    };
}
