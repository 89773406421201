import PropTypes from 'prop-types';
import React from 'react';

import './styles.css';

const Swipe = ({ icon, label }) => (
    <div className="complex-swipeable-list__item-content-right">
        <div className="complex-swipeable-list__content">
            <span className="complex-swipeable-list__icon">{icon}</span>
            {label && <span>{label}</span>}
        </div>
    </div>
);

Swipe.propTypes = {
    icon: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
};

export default Swipe;
