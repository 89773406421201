import Container from '@material-ui/core/Container';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '../../../../components/Icons/ArrowRightIcon';
import Paper from '../../../../components/Paper';
import { styles } from './styles';

const FeedbackSessionsCard = React.memo(({ session }) => {
    const { t } = useTranslation('translation');
    const { feedbackId: currentFeedbackId } = useParams();
    const date = moment(session.date).format('MM/DD/YYYY');
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();
    const feedbackSessionId = session.id;

    const statusData = useMemo(
        () => ({
            red: { color: '#F42727', text: t('notCompleted') },
            yellow: { color: '#F4B627', text: t('stillOpen') },
            green: { color: '#6FCF97', text: t('completed') },
        }),
        [t],
    );

    const statuses = useCallback(
        (status) => {
            switch (status) {
                case 0:
                    return statusData.red;
                case 1:
                    return statusData.green;
                default:
                    return statusData.yellow;
            }
        },
        [statusData.red, statusData.green, statusData.yellow],
    );

    return (
        <Container>
            <Link
                to={`/feedbacks/${currentFeedbackId}/sessions/${feedbackSessionId}`}
                className={classes.link}
            >
                <Paper className={classes.root}>
                    <div className={classes.sessionData}>
                        <div className={classes.date}>{date}</div>
                        <div
                            style={{
                                color: statuses(session.status).color,
                            }}
                        >
                            {statuses(session.status).text}
                        </div>
                    </div>
                    <ArrowRightIcon />
                </Paper>
            </Link>
        </Container>
    );
});

FeedbackSessionsCard.propTypes = {
    session: PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        status: PropTypes.number,
    }).isRequired,
};

export default FeedbackSessionsCard;
