import PropTypes from 'prop-types';
import React from 'react';

const ArrowDownIcon = ({ className }) => (
    <svg
        className={className}
        width="16"
        height="34"
        viewBox="0 0 16 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.29289 33.7071C7.68341 34.0976 8.31658 34.0976 8.7071 33.7071L15.0711 27.3431C15.4616 26.9526 15.4616 26.3195 15.0711 25.9289C14.6805 25.5384 14.0474 25.5384 13.6569 25.9289L8 31.5858L2.34314 25.9289C1.95262 25.5384 1.31945 25.5384 0.92893 25.9289C0.538406 26.3195 0.538406 26.9526 0.92893 27.3431L7.29289 33.7071ZM7 -7.68261e-08L7 33L9 33L9 7.68261e-08L7 -7.68261e-08Z"
            fill="#B82C54"
        />
    </svg>
);

ArrowDownIcon.defaultProps = {
    className: '',
};
ArrowDownIcon.propTypes = {
    className: PropTypes.string,
};

export default ArrowDownIcon;
