import {
    FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_SUCCESS_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_FAILURE_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_FAILURE_ACTION,
    FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_SUCCESS_ACTION,
    FETCH_BEST_DEPARTMENT_ACTION,
    FETCH_BEST_DEPARTMENT_SUCCESS_ACTION,
    FETCH_BEST_DEPARTMENT_FAILURE_ACTION,
} from './employeePerformance.action-types';

export const fetchEmployeeDepartmentModulesPerformanceAction = () => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_ACTION,
});

export const fetchEmployeeDepartmentModulesPerformanceSuccessAction = (
    employeeDepartmentModulesPerformance,
) => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_SUCCESS_ACTION,
    employeeDepartmentModulesPerformance,
});

export const fetchEmployeeDepartmentModulesPerformanceFailureAction = (
    error,
) => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_MODULES_PERFORMANCE_FAILURE_ACTION,
    error,
});

export const fetchEmployeeDepartmentPlayoffPerformanceAction = () => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_ACTION,
});

export const fetchEmployeeDepartmentPlayoffPerformanceSuccessAction = (
    employeeDepartmentPlayoffPerformance,
) => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_SUCCESS_ACTION,
    employeeDepartmentPlayoffPerformance,
});

export const fetchEmployeeDepartmentPlayoffPerformanceFailureAction = (
    error,
) => ({
    type: FETCH_EMPLOYEE_DEPARTMENT_PLAYOFF_PERFORMANCE_FAILURE_ACTION,
    error,
});

export const fetchBestDepartmentAction = () => ({
    type: FETCH_BEST_DEPARTMENT_ACTION,
});

export const fetchBestDepartmentSuccessAction = (bestDepartmentName) => ({
    type: FETCH_BEST_DEPARTMENT_SUCCESS_ACTION,
    bestDepartmentName,
});

export const fetchBestDepartmentFailureAction = (error) => ({
    type: FETCH_BEST_DEPARTMENT_FAILURE_ACTION,
    error,
});
