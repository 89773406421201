import { request } from '../../../utils/request';
import {
    fetchEmployeeDepartmentModulesPerformanceAction,
    fetchEmployeeDepartmentModulesPerformanceSuccessAction,
    fetchEmployeeDepartmentModulesPerformanceFailureAction,
    fetchEmployeeDepartmentPlayoffPerformanceAction,
    fetchEmployeeDepartmentPlayoffPerformanceSuccessAction,
    fetchEmployeeDepartmentPlayoffPerformanceFailureAction,
    fetchBestDepartmentAction,
    fetchBestDepartmentSuccessAction,
    fetchBestDepartmentFailureAction,
} from './employeePerformance.actions';

export function fetchEmployeeDepartmentModulesPerformance() {
    return async (dispatch) => {
        dispatch(fetchEmployeeDepartmentModulesPerformanceAction());
        try {
            const response = await request.get({
                path: 'statistics/department-modules-performance',
            });

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(
                fetchEmployeeDepartmentModulesPerformanceSuccessAction(
                    response.data,
                ),
            );
        } catch (err) {
            dispatch(
                fetchEmployeeDepartmentModulesPerformanceFailureAction(
                    err.message,
                ),
            );
        }
    };
}

export function fetchEmployeeDepartmentPlayoffPerformance() {
    return async (dispatch) => {
        dispatch(fetchEmployeeDepartmentPlayoffPerformanceAction());
        try {
            const response = await request.get({
                path: 'statistics/department-playoff-performance',
            });

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(
                fetchEmployeeDepartmentPlayoffPerformanceSuccessAction(
                    response.data,
                ),
            );
        } catch (err) {
            dispatch(
                fetchEmployeeDepartmentPlayoffPerformanceFailureAction(
                    err.message,
                ),
            );
        }
    };
}

export function fetchBestDepartment() {
    return async (dispatch) => {
        dispatch(fetchBestDepartmentAction());
        try {
            const response = await request.get({
                path: 'statistics/best-department',
            });

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(fetchBestDepartmentSuccessAction(response.data));
        } catch (err) {
            dispatch(fetchBestDepartmentFailureAction(err.message));
        }
    };
}
