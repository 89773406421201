import gql from 'graphql-tag';
import graphqlClient from './graphqlClient';

export default class EmployeeFeedbackService {
    static async getEmployeeFeedbacksList() {
        const response = await graphqlClient.query({
            query: gql`
                query EMPLOYEE_FEEDBACKS_LIST {
                    employeeFeedbacksList {
                        id
                        name
                        sessions {
                            id
                            date
                            sessionData {
                                id
                                data
                                rating
                                date
                                sessionType
                            }
                        }
                        frequency
                        createdAt
                    }
                }
            `,
        });

        return response.data.employeeFeedbacksList;
    }

    static async createEmployeeFeedback(data) {
        const response = await graphqlClient.mutate({
            mutation: gql`
                mutation CREATE_EMPLOYEE_FEEDBACK(
                    $data: EmployeeFeedbackInput!
                ) {
                    employeeFeedbackCreate(data: $data) {
                        id
                        name
                        sessions {
                            id
                            date
                        }
                        frequency
                        createdAt
                    }
                }
            `,
            variables: {
                data,
            },
        });

        return response.data.employeeFeedbackCreate;
    }

    static async deleteEmployeeFeedback(id) {
        const response = await graphqlClient.mutate({
            mutation: gql`
                mutation DELETE_EMPLOYEE_FEEDBACK($id: String!) {
                    employeeFeedbackDelete(id: $id) {
                        id
                    }
                }
            `,
            variables: {
                id,
            },
        });

        return response.data.employeeFeedbackDelete;
    }

    static async createFeedbackSession(data) {
        const response = await graphqlClient.mutate({
            mutation: gql`
                mutation CREATE_FEEDBACK_SESSION($data: FeedbackSessionInput!) {
                    feedbackSessionCreate(data: $data) {
                        id
                        date
                        feedbackId
                    }
                }
            `,
            variables: {
                data,
            },
        });

        return response.data.feedbackSessionCreate;
    }

    static async createOrUpdateSessionData(data) {
        const response = await graphqlClient.mutate({
            mutation: gql`
                mutation UPSERT_SESSION_DATA($data: SessionDataInput!) {
                    sessionDataUpsert(data: $data) {
                        id
                        data
                        rating
                        date
                        sessionType
                        feedbackSessionId
                    }
                }
            `,
            variables: {
                data,
            },
        });

        return response.data.sessionDataUpsert;
    }

    static async sendSessionDataByEmail(data) {
        const response = await graphqlClient.query({
            query: gql`
                query SEND_SESSION_DATA_BY_EMAIL($data: SendSessionDataInput!) {
                    sendSessionDataByEmail(data: $data) {
                        done
                    }
                }
            `,
            variables: {
                data,
            },
        });

        return response.data.sendSessionDataByEmail;
    }
}
