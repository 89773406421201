export function styles() {
    return {
        legendsWrapper: {
            display: 'flex',
            marginTop: '25px',
            justifyContent: 'space-between',
        },
        legend: {
            display: 'flex',
            alignItems: 'center',
        },
        legendIcon: {
            width: '12px',
            height: '12px',
            marginRight: '5px',
        },
        legendLabel: {
            fontSize: 12,
        },
    };
}
