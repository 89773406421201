export const styles = (theme) => ({
    root: {
        display: 'flex',
        margin: 'auto',
        marginTop: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.textColor.white,
        width: '70%',
        height: 34,
        background: theme.palette.red,
        borderRadius: 4,
        border: 'none',
        fontSize: 13,
        fontFamily: `"Poppins", sans-serif`,
        textDecoration: 'none',
    },
    icon: {
        marginLeft: 15,
    },
});
