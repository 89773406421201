import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ScrollView from '../../components/ScrollView';
import Title from '../../components/Title';
import {
    fetchEmployeeFeedbacksList,
    addEmployeeFeedback,
} from '../../store/modules/employeeFeedbacks/employeeFeedbacks.thunks';
import AddEmployeeFeedbackModal from './components/AddEmployeeFeedbackModal/AddEmployeeFeedbackModal';
import EmployeeFeedbacksList from './components/EmployeeFeedbacksList/EmployeeFeedbacksList';
import { styles } from './styles';

const Feedback = React.memo(() => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [modalIsOpened, setModalIsOpened] = useState(false);
    const handleModalClose = useCallback(() => setModalIsOpened(false), [
        setModalIsOpened,
    ]);
    const handleModalOpen = useCallback(() => setModalIsOpened(true), [
        setModalIsOpened,
    ]);
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const classes = makeStyles(() => styles(useTheme(), isLightMode))();

    const initialState = useMemo(
        () => ({
            name: '',
            date: moment().format('YYYY-MM-DD'),
            frequency: 'monthly',
        }),
        [],
    );

    useEffect(() => {
        dispatch(fetchEmployeeFeedbacksList());
    }, [dispatch]);
    const employeeFeedbacksList = useSelector(
        (state) => state.employeeFeedbacks.employeeFeedbacksList,
    );

    const NoEmployeeFeedbacks = () => (
        <Container className={classes.emptyFeedbackRoot}>
            <div className={classes.default}>
                {t('noEmployeeFeedbackTitle')}
            </div>
            <AddIcon style={{ color: '#E3E3E3', fontSize: 125 }} />
        </Container>
    );

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            name: Yup.string()
                .min(2, t('tooShortName'))
                .max(50, t('tooLongName'))
                .required(t('firstNameRequired')),
        });
    }, [t]);

    const doSubmit = useCallback(
        (values, formik) => {
            dispatch(addEmployeeFeedback(values));
            formik.resetForm();
            handleModalClose();
        },
        [dispatch, handleModalClose],
    );

    return (
        <Container className={classes.root} spacing={0}>
            <Container>
                <Formik
                    onSubmit={doSubmit}
                    validationSchema={validationSchema}
                    initialValues={initialState}
                >
                    {(form) => (
                        <AddEmployeeFeedbackModal
                            handleSubmit={form.handleSubmit}
                            errors={form.errors}
                            handleClose={handleModalClose}
                            isOpen={modalIsOpened}
                            handleBlur={form.handleBlur}
                            handleChange={form.handleChange}
                            values={form.values}
                            isValid={form.isValid}
                            dirty={form.dirty}
                        />
                    )}
                </Formik>
            </Container>
            <Container className={classes.title}>
                <Title>{t('employeeFeedback')}</Title>
                <button
                    type="button"
                    onClick={handleModalOpen}
                    className={classes.button}
                >
                    <AddIcon style={{ color: '#B82C54', fontSize: '30px' }} />
                </button>
            </Container>
            <ScrollView>
                {employeeFeedbacksList.length ? (
                    <EmployeeFeedbacksList feedbacks={employeeFeedbacksList} />
                ) : (
                    <NoEmployeeFeedbacks />
                )}
            </ScrollView>
        </Container>
    );
});

export default Feedback;
