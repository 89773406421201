import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button';
import ArrowRightIcon from '../../../../components/Icons/ArrowRightIcon';
import Paper from '../../../../components/Paper';
import Title from '../../../../components/Title';
import {
    sendSessionDataByEmail,
    fetchEmployeeFeedbacksList,
} from '../../../../store/modules/employeeFeedbacks/employeeFeedbacks.thunks';
import { styles } from './styles';

function Alert(props) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SessionData = React.memo(() => {
    const { t } = useTranslation('translation');
    const { feedbackId: currentFeedbackId } = useParams();
    const { sessionId: currentSessionId } = useParams();
    const [successMessage, setSuccessMessage] = useState(false);
    const handleClose = () => {
        setSuccessMessage(false);
    };
    const dispatch = useDispatch();
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();
    useEffect(() => {
        dispatch(fetchEmployeeFeedbacksList());
    }, [dispatch]);

    const { name, date, sessionStatus } = useSelector((state) => {
        const defaultStatus = { prepare: 0, talk: 0, followup: 0 };
        const defaultReturn = { date: null, sessionStatus: defaultStatus };

        const feedback = state.employeeFeedbacks.employeeFeedbacksList.find(
            (feedbackElement) => currentFeedbackId === feedbackElement.id,
        );
        if (!feedback) return defaultReturn;

        const name1 = feedback.name;

        const feedbackSession = feedback.sessions.find(
            (session) => currentSessionId === session.id,
        );
        if (!feedbackSession) return defaultReturn;

        const date1 = feedbackSession.date
            ? moment(feedbackSession.date).format('MM/DD/YYYY')
            : null;

        const { sessionData } = feedbackSession;
        const sessionStatus1 = sessionData
            ? sessionData.reduce(
                  (status, data) =>
                      Object.assign(status, {
                          [data.sessionType]: data.status,
                      }),
                  defaultStatus,
              )
            : defaultStatus;

        return { name: name1, date: date1, sessionStatus: sessionStatus1 };
    });

    const sessionDataTypes = useMemo(
        () => ({
            prepare: t('prepare'),
            talk: t('talk'),
            followup: t('followup'),
        }),
        [t],
    );

    const statusData = useMemo(
        () => ({
            red: { color: '#F42727', text: t('notCompleted') },
            yellow: { color: '#F4B627', text: t('stillOpen') },
            green: { color: '#6FCF97', text: t('completed') },
        }),
        [t],
    );

    const statuses = useCallback(
        (status) => {
            switch (status) {
                case 0:
                    return statusData.red;
                case 1:
                    return statusData.green;
                default:
                    return statusData.yellow;
            }
        },
        [statusData.red, statusData.green, statusData.yellow],
    );

    const doSendEmail = useCallback(() => {
        const data = {
            currentFeedbackId,
            currentSessionId,
        };
        dispatch(sendSessionDataByEmail(data));
        setSuccessMessage(true);
    }, [currentFeedbackId, currentSessionId, setSuccessMessage, dispatch]);

    return (
        <Container className={classes.mainRoot} spacing={0}>
            <Container className={classes.title}>
                <Title>{name || ''}</Title>
                <Title>{date || ''}</Title>
            </Container>
            {Object.keys(sessionDataTypes).map((type) => (
                <Container key={Math.random()}>
                    <Link
                        to={`/feedbacks/${currentFeedbackId}/sessions/${currentSessionId}/${type}`}
                        className={classes.link}
                    >
                        <Paper className={classes.typeRoot}>
                            <div className={classes.sessionData}>
                                <div className={classes.type}>
                                    {sessionDataTypes[type]}
                                </div>
                                <div
                                    style={{
                                        color: statuses(sessionStatus[type])
                                            .color,
                                    }}
                                >
                                    {statuses(sessionStatus[type]).text}
                                </div>
                            </div>
                            <ArrowRightIcon />
                        </Paper>
                    </Link>
                </Container>
            ))}
            <Button
                classes={{ root: classes.submitButton }}
                type="submit"
                variant="contained"
                onClick={doSendEmail}
            >
                {t('sendEmail')}
            </Button>
            <Snackbar
                open={successMessage}
                autoHideDuration={3000}
                onClose={handleClose}
                classes={{ root: classes.snackbar }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert severity="success">{t('emailSent')}</Alert>
            </Snackbar>
        </Container>
    );
});

export default SessionData;
