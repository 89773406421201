import PropTypes from 'prop-types';
import React from 'react';

const TimesIcon = ({ className, onClick, color, width }) => (
    <svg
        className={className}
        onClick={onClick}
        width={width}
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line
            x1="0.707107"
            y1="1.29289"
            x2="19.7071"
            y2="20.2929"
            stroke={color}
            strokeWidth="2"
        />
        <line
            x1="19.7071"
            y1="1.70711"
            x2="0.707107"
            y2="20.7071"
            stroke={color}
            strokeWidth="2"
        />
    </svg>
);

TimesIcon.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#fff',
    width: 22,
};
TimesIcon.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    width: PropTypes.number,
};

export default TimesIcon;
