import { request } from '../../../utils/request';
import {
    fetchEmployeeDepartmentEngagementAction,
    fetchEmployeeDepartmentEngagementSuccessAction,
    fetchEmployeeDepartmentEngagementFailureAction,
} from './employeeDepartmentEngagement.actions';

export function fetchEmployeeDepartmentEngagement() {
    return async (dispatch) => {
        dispatch(fetchEmployeeDepartmentEngagementAction());
        try {
            const response = await request.get({
                path: 'statistics/engagement',
            });

            if (!response) {
                throw new Error('Missing response from API');
            }

            dispatch(
                fetchEmployeeDepartmentEngagementSuccessAction(response.data),
            );
        } catch (err) {
            dispatch(
                fetchEmployeeDepartmentEngagementFailureAction(err.message),
            );
        }
    };
}
