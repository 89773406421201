import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { EmployeeFeedbacksCard } from '../EmployeeFeedbacksCard';
import { styles } from './styles';

const EmployeeFeedbacksList = ({ classes, feedbacks }) => {
    return feedbacks.map((feedback) => (
        <EmployeeFeedbacksCard
            key={Math.random()}
            className={classes.listItem}
            feedback={feedback}
        />
    ));
};

EmployeeFeedbacksList.propTypes = {
    classes: PropTypes.object.isRequired,
    feedbacks: PropTypes.array.isRequired,
};

export default withStyles(styles)(EmployeeFeedbacksList);
