import { request } from '../../../utils/request';
import {
    fetchUserDataAction,
    fetchUserDataSuccessAction,
    fetchUserDataFailureAction,
    fetchUserFeaturesAction,
    fetchUserFeaturesSuccessAction,
    fetchUserFeaturesFailureAction,
} from './auth.actions';

export function fetchUserData() {
    return async (dispatch) => {
        dispatch(fetchUserDataAction());
        try {
            const response = await request.get({
                path: 'users/me',
            });
            if (!response) {
                throw new Error('Missing response from API');
            }
            const { type } = response.data;
            let { position: executive } = response.data.employee;
            executive = executive === 'EXECUTIVE';
            dispatch(fetchUserDataSuccessAction(executive, type));
        } catch (err) {
            dispatch(fetchUserDataFailureAction(err.message));
        }
    };
}

export function fetchUserFeatures() {
    return async (dispatch) => {
        dispatch(fetchUserFeaturesAction());
        try {
            const response = await request.get({
                path: 'statistics/me/features',
            });
            const { OKR_TOOL, FEEDBACK_TOOL } = response.data;
            dispatch(fetchUserFeaturesSuccessAction(OKR_TOOL, FEEDBACK_TOOL));
        } catch (err) {
            dispatch(fetchUserFeaturesFailureAction(err.message));
        }
    };
}
