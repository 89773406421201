import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { styles } from './styles';

const LevelChart = ({
    classes,
    completedModules,
    availableModules,
    progress,
}) => (
    <div className={classes.wrapper}>
        <div className={classes.level}>{completedModules}</div>
        <div className={classes.progressBarWrapper}>
            <div
                className={classes.progressBar}
                style={{ width: `${progress}%` }}
            />
        </div>
        <div className={classes.level}>{availableModules}</div>
    </div>
);

LevelChart.propTypes = {
    classes: PropTypes.object.isRequired,
    completedModules: PropTypes.number.isRequired,
    availableModules: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
};

export default withStyles(styles)(LevelChart);
