import PropTypes from 'prop-types';
import React from 'react';

const ProgressIcon = ({ className, size }) => (
    <svg
        className={className}
        width={size}
        viewBox="0 0 37 22"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M30.2867 1.07131C29.8726 1.10696 29.5338 1.4278 29.5715 1.81996C29.6091 2.21212 29.9479 2.53298 30.362 2.49733H33.2605L25.1672 12.3725C24.7907 12.2299 24.4143 12.123 23.9626 12.123C23.2097 12.123 22.5321 12.4082 22.0051 12.836L17.3749 9.84135C17.4879 9.55615 17.5632 9.23529 17.5632 8.87878C17.5632 7.31015 16.208 6.02673 14.5517 6.02673C12.8954 6.02673 11.5402 7.31015 11.5402 8.87878C11.5402 9.37789 11.6908 9.84135 11.9166 10.2335L5.63016 15.7594C5.17843 15.4741 4.61378 15.2959 4.01148 15.2959C2.35517 15.2959 1 16.5793 1 18.1479C1 19.7166 2.35517 21 4.01148 21C5.6678 21 7.02297 19.7166 7.02297 18.1479C7.02297 17.6488 6.8724 17.1854 6.64653 16.7932L12.933 11.2674C13.3847 11.5526 13.9494 11.7308 14.5517 11.7308C15.3046 11.7308 16.0198 11.4813 16.5468 11.0178L21.1393 13.9768C21.0264 14.2977 20.9511 14.6185 20.9511 14.9394C20.9511 16.508 22.3063 17.7914 23.9626 17.7914C25.6189 17.7914 26.9741 16.508 26.9741 14.9394C26.9741 14.2977 26.7482 13.6916 26.3341 13.2282L34.1263 3.7451V6.02673C34.1263 6.41889 34.4651 6.73974 34.8792 6.73974C35.2933 6.73974 35.6321 6.41889 35.6321 6.02673C35.6321 6.02673 35.6321 6.02674 35.6321 5.99109V1.71301C35.6321 1.32086 35.2933 1 34.8792 1H30.362C30.3243 1.0713 30.3243 1.07131 30.2867 1.07131ZM14.5517 7.48842C15.3798 7.48842 16.0574 8.13013 16.0574 8.91445C16.0574 9.69876 15.3798 10.3405 14.5517 10.3405C13.7235 10.3405 13.0459 9.69876 13.0459 8.91445C13.0459 8.09448 13.7235 7.48842 14.5517 7.48842ZM23.9626 13.549C24.7907 13.549 25.4683 14.1907 25.4683 14.9751C25.4683 15.7594 24.7907 16.4011 23.9626 16.4011C23.1344 16.4011 22.4568 15.7594 22.4568 14.9751C22.4568 14.1907 23.1344 13.549 23.9626 13.549ZM4.01148 16.7576C4.83964 16.7576 5.51723 17.3993 5.51723 18.1836C5.51723 18.9679 4.83964 19.6096 4.01148 19.6096C3.18333 19.6096 2.50574 18.9679 2.50574 18.1836C2.50574 17.3993 3.18333 16.7576 4.01148 16.7576Z"
            strokeMiterlimit="10"
        />
    </svg>
);

ProgressIcon.defaultProps = {
    className: '',
    size: 37,
};
ProgressIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
};

export default ProgressIcon;
