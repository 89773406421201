import PropTypes from 'prop-types';
import React from 'react';

const SurveyIcon = ({ className, size }) => (
    <svg
        className={className}
        width={size}
        viewBox="0 0 32 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.3221 5.59209H18.5786C17.9536 5.59209 17.4602 6.08551 17.4602 6.71051C17.4602 7.33551 17.9536 7.82893 18.5786 7.82893H25.3221C25.9471 7.82893 26.4405 7.33551 26.4405 6.71051C26.4405 6.08551 25.9471 5.59209 25.3221 5.59209Z"
            fill="white"
        />
        <path
            d="M25.3221 11.0526H18.5786C17.9536 11.0526 17.4602 11.5461 17.4602 12.1711C17.4602 12.7961 17.9536 13.2895 18.5786 13.2895H25.3221C25.9471 13.2895 26.4405 12.7961 26.4405 12.1711C26.4734 11.5461 25.9471 11.0526 25.3221 11.0526Z"
            fill="white"
        />
        <path
            d="M25.3221 16.4803H18.5786C17.9536 16.4803 17.4602 16.9737 17.4602 17.5987C17.4602 18.2237 17.9536 18.7171 18.5786 18.7171H25.3221C25.9471 18.7171 26.4405 18.2237 26.4405 17.5987C26.4405 16.9737 25.9471 16.4803 25.3221 16.4803Z"
            fill="white"
        />
        <path
            d="M10.6181 5.49338C7.82205 5.49338 6.53916 7.53285 6.276 9.24338C6.2431 9.44075 6.30889 9.63811 6.44047 9.80259C6.57205 9.96706 6.76942 10.0657 6.99968 10.0657H7.98653C8.34837 10.0657 8.64442 9.80259 8.67731 9.47364C8.80889 8.78285 9.23653 7.92759 10.6181 7.92759C11.6707 7.92759 12.1313 8.4868 12.2957 8.81575C12.526 9.30917 12.4931 9.86838 12.1971 10.2631C11.901 10.6907 11.6049 10.8881 11.2431 11.1184C10.3878 11.7105 9.69705 12.3355 9.56547 14.1118C9.56547 14.3092 9.63126 14.5065 9.76284 14.671C9.89442 14.8026 10.0918 14.9013 10.2892 14.9013H11.276C11.6378 14.9013 11.9668 14.6052 11.9997 14.2434C12.0655 13.5526 12.2299 13.421 12.6576 13.125C13.0523 12.8289 13.6115 12.4671 14.1707 11.7105C15.026 10.5592 15.1247 9.01311 14.4668 7.73022C13.776 6.31575 12.3286 5.49338 10.6181 5.49338Z"
            fill="white"
        />
        <path
            d="M10.7826 18.8487C11.582 18.8487 12.23 18.2007 12.23 17.4013C12.23 16.602 11.582 15.954 10.7826 15.954C9.98326 15.954 9.33525 16.602 9.33525 17.4013C9.33525 18.2007 9.98326 18.8487 10.7826 18.8487Z"
            fill="white"
        />
        <path
            d="M26.901 0H5.91417C3.08522 0 0.782593 2.30263 0.782593 5.13158V19.2105C0.782593 22.0395 3.08522 24.3421 5.91417 24.3421H11.8023L14.0063 28.5526C14.4668 29.4408 15.3879 30 16.4076 30C17.4273 30 18.3484 29.4408 18.8089 28.5526L21.0129 24.3421H26.8681C29.6971 24.3421 31.9997 22.0395 31.9997 19.2105V5.13158C32.0326 2.30263 29.73 0 26.901 0ZM29.7957 19.2105C29.7957 20.7895 28.5129 22.1053 26.901 22.1053H20.355C19.9273 22.1053 19.5655 22.3355 19.3681 22.6974L16.8352 27.5C16.7036 27.7303 16.5063 27.7632 16.4076 27.7632C16.3089 27.7632 16.1115 27.7303 15.98 27.5L13.4471 22.6974C13.2497 22.3355 12.855 22.1053 12.4602 22.1053H5.91417C4.33522 22.1053 3.01943 20.8224 3.01943 19.2105V5.13158C3.01943 3.55263 4.30233 2.23684 5.91417 2.23684H26.901C28.48 2.23684 29.7957 3.51974 29.7957 5.13158V19.2105Z"
            fill="white"
        />
    </svg>
);

SurveyIcon.defaultProps = {
    className: '',
    size: 32,
};
SurveyIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
};

export default SurveyIcon;
