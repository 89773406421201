export const styles = (theme, isLightMode) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 10px',
        marginTop: '8px',
        marginLeft: '8px',
        marginBottom: '8px',
        height: '60px',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    link: {
        width: '98%',
        textDecoration: 'none',
    },
    sessionData: {
        fontSize: '12px',
    },
    date: {
        fontWeight: 600,
    },
});
