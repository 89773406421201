import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import Button from '../../../../components/Button';
import Paper from '../../../../components/Paper';
import {
    addSessionData,
    fetchEmployeeFeedbacksList,
} from '../../../../store/modules/employeeFeedbacks/employeeFeedbacks.thunks';
import { styles } from './styles';

const SessionDataTalk = React.memo(() => {
    const { t } = useTranslation('translation');
    const history = useHistory();
    const { feedbackId: currentFeedbackId } = useParams();
    const { sessionId: currentSessionId } = useParams();
    const dispatch = useDispatch();
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const theme = useTheme();
    const classes = makeStyles(() => styles(theme, isLightMode))();
    useEffect(() => {
        dispatch(fetchEmployeeFeedbacksList());
    }, [dispatch]);

    const sessionData = useSelector((state) => {
        const feedback = state.employeeFeedbacks.employeeFeedbacksList.find(
            (feedbackElement) => currentFeedbackId === feedbackElement.id,
        );
        if (!feedback) return null;
        const feedbackSession = feedback.sessions.find(
            (session) => currentSessionId === session.id,
        );
        if (!feedbackSession) return null;

        const neededSessionData = feedbackSession.sessionData.find(
            (data) => data.sessionType === 'talk',
        );

        if (!neededSessionData) return null;

        return neededSessionData.data;
    });

    const initialState = useMemo(
        () => ({
            compliance: '',
            facts: '',
            observations: '',
            effects: '',
            perspective: '',
            introduction: '',
            wishes: '',
        }),
        [],
    );

    const textFields = useMemo(
        () => ({
            compliance: t('compliance'),
            facts: t('facts'),
            observations: t('observations'),
            effects: t('effects'),
            perspective: t('perspective'),
            introduction: t('introduction'),
            wishes: t('wishes'),
        }),
        [t],
    );

    const doSubmit = useCallback(
        (values) => {
            const body = {
                feedbackSessionId: currentSessionId,
                data: JSON.stringify(values),
                sessionType: 'talk',
            };
            dispatch(addSessionData(body));
            history.push(
                `/feedbacks/${currentFeedbackId}/sessions/${currentSessionId}`,
            );
        },
        [dispatch, history, currentFeedbackId, currentSessionId],
    );
    return (
        <Container className={classes.root} spacing={0}>
            <Formik
                onSubmit={doSubmit}
                initialValues={sessionData || initialState}
                enableReinitialize
            >
                {({
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    handleSubmit,
                }) => (
                    <form className={classes.select}>
                        <Paper className={classes.paper}>
                            <div className={classes.title}>
                                {t('sessionDataTalkTitle')}
                            </div>
                            <div>
                                <div className={classes.formTitles}>
                                    {t('sessionDataTalkFirstSubtitle')}
                                </div>
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.compliance}
                                </InputLabel>
                                <TextField
                                    id="compliance"
                                    name="compliance"
                                    value={values.compliance}
                                    autoFocus
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                            </div>
                            <div>
                                <div className={classes.formTitles}>
                                    {t('sessionDataTalkSecondSubtitle')}
                                </div>
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.facts}
                                </InputLabel>
                                <TextField
                                    id="facts"
                                    name="facts"
                                    value={values.facts}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.observations}
                                </InputLabel>
                                <TextField
                                    id="observations"
                                    name="observations"
                                    value={values.observations}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.effects}
                                </InputLabel>
                                <TextField
                                    id="effects"
                                    name="effects"
                                    value={values.effects}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                            </div>
                            <div>
                                <div className={classes.formTitles}>
                                    {t('sessionDataTalkThirdSubtitle')}
                                </div>
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.perspective}
                                </InputLabel>
                                <TextField
                                    id="perspective"
                                    name="perspective"
                                    value={values.perspective}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.introduction}
                                </InputLabel>
                                <TextField
                                    id="introduction"
                                    name="introduction"
                                    value={values.introduction}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                                <InputLabel className={classes.inputTitles}>
                                    {textFields.wishes}
                                </InputLabel>
                                <TextField
                                    id="wishes"
                                    name="wishes"
                                    value={values.wishes}
                                    multiline
                                    rows={6}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={classes.input}
                                    error={!!errors}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            color: isLightMode
                                                ? '#0E1A24'
                                                : '#FAFDFF',
                                        },
                                    }}
                                    placeholder={t('placeholder')}
                                />
                            </div>
                            <Button
                                classes={{ root: classes.submitButton }}
                                type="submit"
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                {t('saveOverview')}
                            </Button>
                        </Paper>
                    </form>
                )}
            </Formik>
        </Container>
    );
});

export default SessionDataTalk;
