import {
    FETCH_DASHBOARD_DATA_ACTION,
    FETCH_DASHBOARD_DATA_SUCCESS_ACTION,
    FETCH_DASHBOARD_DATA_FAILURE_ACTION,
} from './dashboard.action-types';

const initialState = {
    progress: null,
    completed: false,
    // scoreSummary: {},
    // badges: [],
    isLoadingDashboardData: true,
    error: null,
    completedModules: null,
    availableModules: null,
};

export function dashboard(state = initialState, action) {
    switch (action.type) {
        case FETCH_DASHBOARD_DATA_ACTION: {
            return {
                ...state,
                isLoadingDashboardData: true,
                error: null,
            };
        }
        case FETCH_DASHBOARD_DATA_SUCCESS_ACTION: {
            return {
                ...state,
                progress: action.progress,
                completed: action.completed,
                isLoadingDashboardData: false,
                // scoreSummary: action.scoreSummary,
                // badges: action.badges,
                completedModules: action.completedModules,
                availableModules: action.availableModules,
                error: null,
            };
        }
        case FETCH_DASHBOARD_DATA_FAILURE_ACTION: {
            return {
                ...state,
                progress: null,
                completed: false,
                isLoadingDashboardData: false,
                // scoreSummary: {},
                // badges: [],
                completedModules: null,
                availableModules: null,
                error: action.error,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
