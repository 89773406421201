export const styles = (theme, isLightMode) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 0,
        alignItems: 'center',
        padding: '10px 10px',
        height: '60px',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    link: {
        width: '100%',
        textDecoration: 'none',
    },
    userData: {
        fontSize: '12px',
    },
    name: {
        fontWeight: 600,
    },
    swipeable: {
        marginBottom: '15px',
    },
    modalRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    content: {
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
        boxShadow: isLightMode
            ? theme.palette.paperComponent.light.boxShadow
            : theme.palette.paperComponent.dark.boxShadow,
        width: '100%',
        margin: '0 36px',
        maxWidth: 768,
    },
    headerWrapper: {
        position: 'relative',
        padding: '15px 15px 0px 15px',
        display: 'flex',
        width: '100%',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: '100%',
        fontSize: '15px',
    },
    closeIcon: {
        position: 'absolute',
    },
    submitButton: {
        margin: '10px',
        width: '30%',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        fontSize: '15px',
    },
});
