import { request } from '../../../utils/request';
import {
    fetchDashboardDataAction,
    fetchDashboardDataSuccessAction,
    fetchDashboardDataFailureAction,
} from './dashboard.actions';

export function fetchDashboardData() {
    return async (dispatch) => {
        dispatch(fetchDashboardDataAction());
        try {
            const response = await request.get({
                path: 'statistics/overview',
            });

            if (!response) {
                throw new Error('Missing response from API');
            }

            const {
                progress,
                completed,
                // scoreSummary,
                // badges,
                completedModules,
                availableModules,
            } = response.data;

            dispatch(
                fetchDashboardDataSuccessAction(
                    progress,
                    completed,
                    // scoreSummary,
                    // badges,
                    completedModules,
                    availableModules,
                ),
            );
        } catch (err) {
            dispatch(fetchDashboardDataFailureAction(err.message));
        }
    };
}
