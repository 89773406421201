export const FETCH_EMPLOYEE_FEEDBACKS_ACTION =
    'FETCH_EMPLOYEE_FEEDBACKS_ACTION';
export const FETCH_EMPLOYEE_FEEDBACKS_SUCCESS_ACTION =
    'FETCH_EMPLOYEE_FEEDBACKS_SUCCESS_ACTION';
export const FETCH_EMPLOYEE_FEEDBACKS_FAILURE_ACTION =
    'FETCH_EMPLOYEE_FEEDBACKS_FAILURE_ACTION';
export const CREATE_EMPLOYEE_FEEDBACK_ACTION =
    'CREATE_EMPLOYEE_FEEDBACK_ACTION';
export const CREATE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION =
    'CREATE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION';
export const CREATE_EMPLOYEE_FEEDBACK_FAILURE_ACTION =
    'CREATE_EMPLOYEE_FEEDBACK_FAILURE_ACTION';
export const DELETE_EMPLOYEE_FEEDBACK_ACTION =
    'DELETE_EMPLOYEE_FEEDBACK_ACTION';
export const DELETE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION =
    'DELETE_EMPLOYEE_FEEDBACK_SUCCESS_ACTION';
export const DELETE_EMPLOYEE_FEEDBACK_FAILURE_ACTION =
    'DELETE_EMPLOYEE_FEEDBACK_FAILURE_ACTION';
export const CREATE_FEEDBACK_SESSION_ACTION = 'CREATE_FEEDBACK_SESSION_ACTION';
export const CREATE_FEEDBACK_SESSION_SUCCESS_ACTION =
    'CREATE_FEEDBACK_SESSION_SUCCESS_ACTION';
export const CREATE_FEEDBACK_SESSION_FAILURE_ACTION =
    'CREATE_FEEDBACK_SESSION_FAILURE_ACTION';
export const CREATE_OR_UPDATE_SESSION_DATA_ACTION =
    'CREATE_OR_UPDATE_SESSION_DATA_ACTION';
export const CREATE_OR_UPDATE_SESSION_DATA_SUCCESS_ACTION =
    'CREATE_OR_UPDATE_SESSION_DATA_SUCCESS_ACTION';
export const CREATE_OR_UPDATE_SESSION_DATA_FAILURE_ACTION =
    'CREATE_OR_UPDATE_SESSION_DATA_FAILURE_ACTION';
export const SEND_SESSION_DATA_BY_EMAIL_ACTION =
    'SEND_SESSION_DATA_BY_EMAIL_ACTION';
export const SEND_SESSION_DATA_BY_EMAIL_SUCCESS_ACTION =
    'SEND_SESSION_DATA_BY_EMAIL_SUCCESS_ACTION';
export const SEND_SESSION_DATA_BY_EMAIL_FAILURE_ACTION =
    'SEND_SESSION_DATA_BY_EMAIL_FAILURE_ACTION';
