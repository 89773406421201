import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ObjectiveCard } from '../ObjectiveCard';
import { styles } from './styles';

const ObjectiveCardList = ({ classes, employeeOKRs }) => (
    <>
        {employeeOKRs.map((employeeOKR, i) => {
            return (
                <ObjectiveCard
                    key={Math.random()}
                    title={employeeOKR.objective}
                    progress={employeeOKR.averageProgress}
                    order={i + 1}
                    className={classes.listItem}
                />
            );
        })}
    </>
);

ObjectiveCardList.propTypes = {
    classes: PropTypes.object.isRequired,
    employeeOKRs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withStyles(styles)(ObjectiveCardList);
