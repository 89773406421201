export const styles = (theme, isLightMode) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '17px 25px 17px 20px',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    link: {
        display: 'block',
        textDecoration: 'none',
    },
    titleWrapper: {
        flex: 1,
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
    },
    subTitle: {
        fontSize: 14,
    },
    chart: {
        width: 72,
        height: 69,
    },
    chartImg: {
        width: 73,
        height: 69,
        marginRight: 21,
    },
});
