import { Container } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import { Loading } from '../../components/Loading';
import Title from '../../components/Title';
import { fetchEmployeeOKRs } from '../../store/modules/employeeOKRs/employeeOKRs.thunks';
import { ObjectiveCardList } from './components/ObjectiveCardList';
import { SelectQuarterModal } from './components/SelectQuarterModal';
import { styles } from './styles';

const OKRs = React.memo(() => {
    const { t, i18n } = useTranslation('translation');
    const dispatch = useDispatch();
    const [modalIsOpened, setModalIsOpened] = useState(false);
    useEffect(() => {
        dispatch(fetchEmployeeOKRs());
    }, [dispatch]);
    const isLightMode = useSelector((state) => state.auth.isLightMode);
    const classes = makeStyles(() => styles(useTheme(), isLightMode))();
    const { isOKR, isLoadingFeatures } = useSelector((state) => state.auth);

    const options = useMemo(() => {
        const optionDate1 = moment(isOKR);
        const optionDate2 = moment(isOKR).add(1, 'quarter');
        const optionDate3 = moment(isOKR).add(2, 'quarter');
        const optionDate4 = moment(isOKR).add(3, 'quarter');

        if (i18n.language !== 'de') {
            return [
                {
                    label: `${t('quarter')} ${optionDate1.quarter()} ${t(
                        'of',
                    )} ${optionDate1.year()}`,
                    value: optionDate1.quarter(),
                },
                {
                    label: `${t('quarter')} ${optionDate2.quarter()} ${t(
                        'of',
                    )} ${optionDate2.year()}`,
                    value: optionDate2.quarter(),
                },
                {
                    label: `${t('quarter')} ${optionDate3.quarter()} ${t(
                        'of',
                    )} ${optionDate3.year()}`,
                    value: optionDate3.quarter(),
                },
                {
                    label: `${t('quarter')} ${optionDate4.quarter()} ${t(
                        'of',
                    )} ${optionDate4.year()}`,
                    value: optionDate4.quarter(),
                },
            ];
        }
        return [
            {
                label: `${optionDate1.quarter()} ${t(
                    'quarter',
                )} ${optionDate1.year()}`,
                value: optionDate1.quarter(),
            },
            {
                label: `${optionDate2.quarter()} ${t(
                    'quarter',
                )} ${optionDate2.year()}`,
                value: optionDate2.quarter(),
            },
            {
                label: `${optionDate3.quarter()} ${t(
                    'quarter',
                )} ${optionDate3.year()}`,
                value: optionDate3.quarter(),
            },
            {
                label: `${optionDate4.quarter()} ${t(
                    'quarter',
                )} ${optionDate4.year()}`,
                value: optionDate4.quarter(),
            },
        ];
    }, [isOKR, t, i18n.language]);

    const [selectValue, setSelectValue] = useState(options[0]);

    useEffect(() => {
        setSelectValue(options[0]);
    }, [options]);

    const employeeOKRsList = useSelector(
        (state) => state.employeeOKRs.employeeOKRsList,
    );

    const handleModalClose = () => setModalIsOpened(false);
    const handleModalOpen = () => setModalIsOpened(true);
    const onSelectChange = (option) => {
        setSelectValue(option);
        handleModalClose();
    };

    const selectedEmployeeOKRs = useMemo(() => {
        if (!selectValue) return [];
        return employeeOKRsList.filter(
            ({ quarter }) => quarter === selectValue.value,
        );
    }, [employeeOKRsList, selectValue]);

    return (
        <>
            {isLoadingFeatures && !selectValue && <Loading />}
            {!isLoadingFeatures && selectValue && (
                <Container className={classes.root} spacing={0}>
                    <SelectQuarterModal
                        onChange={onSelectChange}
                        handleClose={handleModalClose}
                        isOpened={modalIsOpened}
                        options={options}
                        value={selectValue}
                        isLightMode={isLightMode}
                    />
                    <div className={classes.header}>
                        <div>
                            <Title>{t('okrsTitle')}</Title>
                            <div className={classes.subTitle}>
                                {selectValue.label}
                            </div>
                        </div>
                        <div>
                            <Button
                                classes={{ root: classes.chooseButton }}
                                onClick={handleModalOpen}
                                withIcon={false}
                            >
                                {t('showQuarters')}
                            </Button>
                        </div>
                    </div>
                    <ObjectiveCardList employeeOKRs={selectedEmployeeOKRs} />
                    <div className={classes.alterText}>
                        {!selectedEmployeeOKRs.length &&
                            selectValue.value === options[3].value &&
                            t('messageOne')}
                        {!selectedEmployeeOKRs.length &&
                            selectValue.value !== options[3].value &&
                            t('messageTwo')}
                    </div>
                </Container>
            )}
        </>
    );
});

export default OKRs;
