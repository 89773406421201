import {
    FETCH_DASHBOARD_DATA_ACTION,
    FETCH_DASHBOARD_DATA_SUCCESS_ACTION,
    FETCH_DASHBOARD_DATA_FAILURE_ACTION,
} from './dashboard.action-types';

export const fetchDashboardDataAction = () => ({
    type: FETCH_DASHBOARD_DATA_ACTION,
});

export const fetchDashboardDataSuccessAction = (
    progress,
    completed,
    // scoreSummary,
    // badges,
    completedModules,
    availableModules,
) => ({
    type: FETCH_DASHBOARD_DATA_SUCCESS_ACTION,
    progress,
    completed,
    // scoreSummary,
    // badges,
    completedModules,
    availableModules,
});

export const fetchDashboardDataFailureAction = (error) => ({
    type: FETCH_DASHBOARD_DATA_FAILURE_ACTION,
    error,
});
