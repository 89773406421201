export const styles = (theme, isLightMode) => ({
    backdrop: {
        height: '100vh',
        width: '100vw',
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    root: {
        paddingTop: '60px',
        paddingBottom: '30px',
        maxWidth: '700px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    carousel: {
        maxWidth: 700,
        textAlign: 'center',
    },
    carouselPaper: {
        padding: '0px 12px',
        margin: '24px 35px 0',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    spinnerWrapper: {
        display: 'flex',
        height: '250px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    bestDepartmentBadgeSection: {
        color: theme.palette.red,
        marginTop: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    bestDepartmentBadgeText: {
        marginTop: '10px',
        maxWidth: '150px',
        fontSize: '12px',
        textAlign: 'center',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
    },
    usageBadgesSection: {
        margin: '32px 52px 35px',
        maxWidth: 650,
        textAlign: 'center',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    usageBadgesWrapper: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'space-between',
    },
    usageBadge: {
        width: '43%',
    },
    usageBadgeBoldText: {
        fontWeight: 600,
    },
    alterText: {
        fontSize: 16,
        margin: '100px 0',
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
});
