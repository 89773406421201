import { connect } from 'react-redux';
import { fetchUserData } from '../../store/modules/auth/auth.thunks';
import { NavBar as PresentationalComponent } from './NavBar.component';

const mapStateToProps = (state) => ({
    token: state.auth.token,
    isLightMode: state.auth.isLightMode,
});

const mapDispatchToProps = (dispatch) => ({
    getUserData: () => dispatch(fetchUserData()),
});

export const NavBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PresentationalComponent);
