import React from 'react';
import { useSelector } from 'react-redux';
import { LevelChart as BaseLevelChart } from '../../../../components/LevelChart';

export default React.memo(() => {
    const { completedModules, availableModules } = useSelector(
        (state) => state.dashboard,
    );
    const progress = (completedModules / availableModules) * 100;

    return (
        <BaseLevelChart
            completedModules={completedModules}
            availableModules={availableModules}
            progress={progress}
        />
    );
});
