import PropTypes from 'prop-types';
import React from 'react';

const ArrowUpIcon = ({ className }) => (
    <svg
        className={className}
        width="16"
        height="34"
        viewBox="0 0 16 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.70711 0.292892C8.31658 -0.0976295 7.68342 -0.0976296 7.29289 0.292892L0.928933 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292892ZM9 34L9 1L7 1L7 34L9 34Z"
            fill="#6FCF97"
        />
    </svg>
);

ArrowUpIcon.defaultProps = {
    className: '',
};
ArrowUpIcon.propTypes = {
    className: PropTypes.string,
};

export default ArrowUpIcon;
