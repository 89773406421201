import PropTypes from 'prop-types';
import React from 'react';

const OverviewIcon = ({ className, size }) => (
    <svg
        className={className}
        width={size}
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M34.9265 16.2908C34.9265 16.2541 34.9265 16.2541 34.9265 16.2908C34.9265 16.2541 34.9265 16.2541 34.9265 16.2908C34.5222 12.4681 32.9049 9.04973 30.4054 6.40324C30.4054 6.40324 30.4054 6.36649 30.3686 6.36649L30.3319 6.32973C27.2443 3.05838 22.8703 1 18.0184 1C15.4086 1 12.9459 1.58811 10.7405 2.65405C10.7038 2.65405 10.7038 2.65405 10.667 2.69081L10.6303 2.72757C4.93297 5.44757 1 11.2919 1 18.0184C1 27.3914 8.60865 35 18.0184 35C27.4281 35 35 27.3914 35 18.0184C35 17.4303 34.9632 16.8422 34.9265 16.2908ZM29.9276 7.50595C31.9492 9.78487 33.3092 12.6519 33.7503 15.813L19.5622 17.2832L29.9276 7.50595ZM29.1924 6.6973L18.1654 17.0627L11.6227 3.42595C13.5708 2.58054 15.7395 2.1027 17.9816 2.1027C22.3557 2.1027 26.3254 3.86703 29.1924 6.6973ZM18.0184 33.8973C9.23351 33.8973 2.1027 26.7665 2.1027 18.0184C2.1027 11.9168 5.55784 6.58703 10.6303 3.94054L17.5038 18.2757C17.5405 18.3124 17.5773 18.3859 17.6141 18.4227C17.6508 18.4595 17.6876 18.4962 17.7243 18.4962H17.7611C17.7978 18.4962 17.8346 18.533 17.8714 18.533H17.9081C17.9449 18.533 17.9816 18.5697 18.0551 18.5697C18.0919 18.5697 18.0919 18.5697 18.1286 18.5697L33.9341 16.9524C33.9708 17.32 33.9708 17.6508 33.9708 18.0184C33.8973 26.7665 26.7665 33.8973 18.0184 33.8973Z" />
        <path
            d="M34.9265 16.2908C34.9265 16.2541 34.9265 16.2541 34.9265 16.2908C34.9265 16.2541 34.9265 16.2541 34.9265 16.2908ZM34.9265 16.2908C34.5222 12.4681 32.9049 9.04973 30.4054 6.40324C30.4054 6.40324 30.4054 6.36649 30.3686 6.36649L30.3319 6.32973C27.2443 3.05838 22.8703 1 18.0184 1C15.4086 1 12.9459 1.58811 10.7405 2.65405C10.7038 2.65405 10.7038 2.65405 10.667 2.69081L10.6303 2.72757C4.93297 5.44757 1 11.2919 1 18.0184C1 27.3914 8.60865 35 18.0184 35C27.4281 35 35 27.3914 35 18.0184C35 17.4303 34.9632 16.8422 34.9265 16.2908ZM29.9276 7.50595C31.9492 9.78487 33.3092 12.6519 33.7503 15.813L19.5622 17.2832L29.9276 7.50595ZM29.1924 6.6973L18.1654 17.0627L11.6227 3.42595C13.5708 2.58054 15.7395 2.1027 17.9816 2.1027C22.3557 2.1027 26.3254 3.86703 29.1924 6.6973ZM18.0184 33.8973C9.23351 33.8973 2.1027 26.7665 2.1027 18.0184C2.1027 11.9168 5.55784 6.58703 10.6303 3.94054L17.5038 18.2757C17.5405 18.3124 17.5773 18.3859 17.6141 18.4227C17.6508 18.4595 17.6876 18.4962 17.7243 18.4962H17.7611C17.7978 18.4962 17.8346 18.533 17.8714 18.533H17.9081C17.9449 18.533 17.9816 18.5697 18.0551 18.5697C18.0919 18.5697 18.0919 18.5697 18.1286 18.5697L33.9341 16.9524C33.9708 17.32 33.9708 17.6508 33.9708 18.0184C33.8973 26.7665 26.7665 33.8973 18.0184 33.8973Z"
            strokeMiterlimit="10"
        />
    </svg>
);

OverviewIcon.defaultProps = {
    className: '',
    size: 36,
};
OverviewIcon.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
};

export default OverviewIcon;
