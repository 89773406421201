export const styles = (theme, isLightMode) => ({
    root: {
        padding: '0 24px',
    },
    submitButton: {
        marginTop: '20px',
        marginBottom: '20px',
        width: '80%',
    },
    paper: {
        margin: '60px 0px 30px 0px',
        padding: '1px 16px 10px 20px',
        [theme.breakpoints.up(theme.sizes.mobileThreshold)]: {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
        backgroundColor: isLightMode
            ? theme.palette.common.light.background
            : theme.palette.common.dark.background,
    },
    input: {
        border: '1px solid #828282',
        borderRadius: '5px',
        paddingLeft: 8,
        paddingTop: 4,
    },
    title: {
        marginTop: '20px',
        fontWeight: 600,
        fontSize: 20,
    },
    formTitles: {
        paddingTop: '20px',
        fontWeight: 600,
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
    },
    inputTitles: {
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: 14,
        color: isLightMode
            ? theme.palette.common.light.text
            : theme.palette.common.dark.text,
    },
});
