import { Modal, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TimesIcon from '../../../../components/Icons/TimesIcon';
import Title from '../../../../components/Title';
import { styles } from './styles';

const SelectQuarterModal = ({
    classes,
    isOpened,
    onChange,
    handleClose,
    options,
    value,
}) => {
    const { t } = useTranslation('translation');

    return (
        <Modal
            open={isOpened}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.root}>
                <div className={classes.content}>
                    <div className={classes.headerWrapper}>
                        <TimesIcon
                            className={classes.closeIcon}
                            onClick={handleClose}
                            color="#0A96BE"
                        />
                        <div className={classes.header}>
                            <Title>{t('quarterModalTitle')}</Title>
                        </div>
                    </div>
                    <div className={classes.select}>
                        {options.map((option) => (
                            <div
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex="0"
                                onClick={() => onChange(option)}
                                className={`${classes.option} ${
                                    option.value === value.value
                                        ? classes.optionSelected
                                        : ''
                                }`}
                                key={option.value}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

SelectQuarterModal.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpened: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectQuarterModal);
