import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NavBar } from '../components/NavBar';
import { DashboardPage } from '../pages/Dashboard';
import { DepartmentProgress } from '../pages/DepartmentProgress';
import { Feedback } from '../pages/Feedback';
import { Sessions } from '../pages/FeedbackSessions';
import { SessionData } from '../pages/FeedbackSessions/components/SessionData';
import { SessionDataFollowup } from '../pages/FeedbackSessions/components/SessionDataFollowup';
import { SessionDataPrepare } from '../pages/FeedbackSessions/components/SessionDataPrepare';
import { SessionDataTalk } from '../pages/FeedbackSessions/components/SessionDataTalk';
import { OKRDetails } from '../pages/OKRDetails';
import { OKRs } from '../pages/OKRs';
import { authSuccessAction } from '../store/modules/auth/auth.actions';
import { fetchUserFeatures } from '../store/modules/auth/auth.thunks';
import ProtectedRoute from './ProtectedRoute';

export const Router = () => {
    const dispatch = useDispatch();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const isLightMode = urlParams.get('mode') === 'light';
    const currentLanguage = urlParams.get('language');
    useEffect(() => {
        dispatch(authSuccessAction(token, isLightMode, currentLanguage));
        dispatch(fetchUserFeatures());
    }, [dispatch, token, isLightMode, currentLanguage]);

    return (
        <>
            {token && <NavBar />}
            {token ? (
                <Switch>
                    <ProtectedRoute
                        path="/department-progress"
                        isAuthenticated={!!token}
                        component={DepartmentProgress}
                    />

                    <ProtectedRoute
                        path="/dashboard"
                        isAuthenticated={!!token}
                        component={DashboardPage}
                    />

                    <ProtectedRoute
                        exact
                        path="/feedbacks"
                        isAuthenticated={!!token}
                        component={Feedback}
                    />

                    <ProtectedRoute
                        exact
                        path="/feedbacks/:feedbackId/sessions"
                        isAuthenticated={!!token}
                        component={Sessions}
                    />

                    <ProtectedRoute
                        exact
                        path="/feedbacks/:feedbackId/sessions/:sessionId"
                        isAuthenticated={!!token}
                        component={SessionData}
                    />

                    <ProtectedRoute
                        exact
                        path="/feedbacks/:feedbackId/sessions/:sessionId/prepare"
                        isAuthenticated={!!token}
                        component={SessionDataPrepare}
                    />

                    <ProtectedRoute
                        exact
                        path="/feedbacks/:feedbackId/sessions/:sessionId/talk"
                        isAuthenticated={!!token}
                        component={SessionDataTalk}
                    />

                    <ProtectedRoute
                        exact
                        path="/feedbacks/:feedbackId/sessions/:sessionId/followup"
                        isAuthenticated={!!token}
                        component={SessionDataFollowup}
                    />

                    <ProtectedRoute
                        exact
                        path="/okrs"
                        isAuthenticated={!!token}
                        component={OKRs}
                    />

                    <ProtectedRoute
                        path="/okrs/:objective"
                        isAuthenticated={!!token}
                        component={OKRDetails}
                    />

                    <Route path="/" exact>
                        <Redirect to="/dashboard" />
                    </Route>
                </Switch>
            ) : (
                <></>
            )}
        </>
    );
};
