import {
    FETCH_EMPLOYEE_OKRS_ACTION,
    FETCH_EMPLOYEE_OKRS_FAILURE_ACTION,
    FETCH_EMPLOYEE_OKRS_SUCCESS_ACTION,
} from './employeeOKRs.action-types';

export const fetchEmployeeOKRsAction = () => ({
    type: FETCH_EMPLOYEE_OKRS_ACTION,
});

export const fetchEmployeeOKRsSuccessAction = (employeeOKRsList) => ({
    type: FETCH_EMPLOYEE_OKRS_SUCCESS_ACTION,
    employeeOKRsList,
});

export const fetchEmployeeOKRsFailureAction = (error) => ({
    type: FETCH_EMPLOYEE_OKRS_FAILURE_ACTION,
    error,
});
